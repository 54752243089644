$(function(){
	initProductFromProductPage();
	initProductFromBasketModal();
	initWishList();
	initSorting();

	function initSorting(){
		$('.js-selected-list-item').click(function(){
			$(this).parent().fadeOut();
		});
	}

	function initWishList(){
		$(document).on('click', '.js-wishlist-add', function(e) {
			e.preventDefault();

			var el = this;
			if( $(this).attr('data-wishlist') == 1 ) {
				wishlist.remove( $(this).attr('data-product-id'), function() {
					$(el).data('wishlist', 0);
					$(el).removeClass('active');
				} );
			} else {
				wishlist.add( $(this).attr('data-product-id'), function() {
					$(el).data('wishlist', 1);
					$(el).addClass('active');
				} );
			}
		});
	}

	function initProductFromBasketModal(){
		initChangeQuantity();
		initChangeSize();
		initDeleteProduct();

		function initDeleteProduct(){
			$('.js-basket-modal-products-list').on('click', '.js-cart-delete', function(e) {
				e.preventDefault();
				shoppingCart.deleteProduct({
					products_id: $(this).attr('data-products-id')
				});
				$(this).closest('.js-basket-product-item').remove();
			});
		}

		function initChangeQuantity(){
			$('.js-basket-modal-products-list').on('change', '.js-basket-change-count', function(e) {
				e.preventDefault();
				shoppingCart.changeProductCount(getProductId($(this)),$(this).val());
				setNewProductData($(this));
			});

			function setNewProductData(quantityElement){
				var productDataElement = $(quantityElement).closest('.js-basket-product-item'),
					productQuantity = $(quantityElement).val();

				$(productDataElement).attr('data-quantity',productQuantity);
			}

			function getProductId(quantityElement){
				var productDataElement = $(quantityElement).closest('.js-basket-product-item'),
					productId = $(productDataElement).attr('data-product-id'),
					productOptionId = $(productDataElement).attr('data-option-id'),
					productOptionValue = $(productDataElement).attr('data-option-value');

				return productId+"{"+productOptionId+"}"+productOptionValue;
			}
		}

		function initChangeSize(){
			$('.js-basket-modal-products-list').on('change', '.js-basket-change-size', function(e) {
				e.preventDefault();

				var productSizeElement = $(this).closest('.js-basket-product-item').find('.js-basket-product-size'),
					sizeText = $(this).find('option:selected').text()

				$(productSizeElement).text(sizeText);
				$.post('/ajax_shopping_cart.php?action=update_product_new',getProductData($(this)))
					.done(function( data ) {
						shoppingCart.reloadPopupCallback();
					});
				setNewProductData($(this));
			});

			function setNewProductData(sizeElement){
				var selectOptionData = $(sizeElement).val().split('|'),
					selectOptionId = selectOptionData[0],
					selectOptionValue = selectOptionData[1],
					productDataElement = $(sizeElement).closest('.js-basket-product-item');

				$(productDataElement).attr('data-option-id',selectOptionId);
				$(productDataElement).attr('data-option-value',selectOptionValue);
			}

			function getProductData(sizeElement){
				var selectOptionData = $(sizeElement).val().split('|'),
					selectOptionId = selectOptionData[0],
					selectOptionValue = selectOptionData[1],
					productDataElement = $(sizeElement).closest('.js-basket-product-item'),
					productId = $(productDataElement).attr('data-product-id'),
					productQuantity = $(productDataElement).attr('data-quantity'),
					productOptionId = $(productDataElement).attr('data-option-id'),
					productOptionValue = $(productDataElement).attr('data-option-value');

				return [
					{
						"name": "id["+productId+"{"+productOptionId+"}"+productOptionValue+"]["+productOptionId+"]",
						"value": productOptionValue
					},
					{
						"name": "products_id[]",
						"value": productId+"{"+productOptionId+"}"+productOptionValue
					},
					{
						"name": "cart_size["+productId+"{"+productOptionId+"}"+productOptionValue+"]",
						"value": productId+"{"+selectOptionId+"}"+selectOptionValue
					},
					{
						"name": "cart_quantity["+productId+"{"+productOptionId+"}"+productOptionValue+"]",
						"value": productQuantity
					}
				];
			}
		}

	}

	/**
	 * �������� � ��������
	 */
	function initProductFromProductPage() {
		initChangeSize();
		initAddToBasket();

		function initAddToBasket() {
			$(document).on('click', '.js-add-product', function (e) {
				var me = $(this),
					isDisabled = getProductSizeDisabled(me);

				e.preventDefault();
				if(isDisabled){
					$(this).addClass('error-empty-count');
					setTimeout(function(){
						$(me).removeClass('error-empty-count');
					},3000);
					return true;
				}else{
					$(me).removeClass('error-empty-count');
				}

				var productData = getProductDataArr(me);

				if(productData.length) {
					$(this).addClass('m-preloader');
					shoppingCart.addProducts({
						product_info: productData,
					}, {
						checkDublicate: false
					});
					$('.js-basket-modal-block').addClass('active');
				}
			});
		}

		function getProductSizeDisabled(element){
			if($(element).attr('data-option-disabled')){
				return Number($(element).attr('data-option-disabled'));
			}

			var dataProductEl = $(element).closest('.js-product-data');

			return Number($(dataProductEl).attr('data-option-disabled'));
		}

		function initChangeSize(){
			$(document).on('click', '.js-change-product-size', function (e) {
				var dataProductEl = $(this).closest('.js-product-data');

				e.preventDefault();
				$(dataProductEl).attr('data-option-name',$(this).attr('data-option-name'))
					.attr('data-option-value',$(this).attr('data-option-value'))
					.attr('data-option-disabled',$(this).attr('data-option-disabled'));
				setActiveSize($(this));

				function setActiveSize(currentSizeEl){
					$(currentSizeEl).closest('.js-product-sizes-list').find('.js-change-product-size').removeAttr('data-selected');
					$(currentSizeEl).attr('data-selected','true');
					if($(currentSizeEl).hasClass('empty')){
						$('.js-add-product').attr('disabled','disabled');
					}else{
						$('.js-add-product').removeAttr('disabled');
					}
				}
			});
		}

		function getProductDataArr(element){
			var hasDataProductEl = $(element).closest('.js-product-data').length;

			if(!hasDataProductEl){
				return [];
			}

			var dataProductEl = $(element).closest('.js-product-data');

			return [
				{
					"name": 'id'+($(element).attr('data-option-name') ? $(element).attr('data-option-name') : $(dataProductEl).attr('data-option-name')),
					"value": $(element).attr('data-option-value') ? $(element).attr('data-option-value') : $(dataProductEl).attr('data-option-value')
				},
				{
					"name": "cart_quantity",
					"value": $(dataProductEl).attr('data-cart-quantity')
				},
				{
					"name": "products_id",
					"value": $(dataProductEl).attr('data-products-id')
				},
				{
					"name": "param",
					"value": $(dataProductEl).attr('data-param')
				},
				{
					"name": "presents_artikul",
					"value": $(dataProductEl).attr('data-presents-artikul')
				},
				{
					"name": "selected_attribute",
					"value": $(dataProductEl).attr('data-selected-attribute')
				}
			];
		}
	}
});