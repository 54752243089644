$(document).on('mouseenter', '.star', function(){
	var form = $(this).closest('form');
	$('.star', form).removeClass('active');
	var hovered = $(this).index();
	$('.star', form).each(function( key, value ){
		if ( key <= hovered ) {
			$(value).addClass('active');
		}
	});
});
$(document).on('mouseleave', '.star', function(){
	var form = $(this).closest('form');
	$('.star', form).removeClass('active');
	var selected = $('input[name=rating]:checked');
	if ( selected.length > 0 ) {
		var selectedIndex = selected.closest('.star').index();
		$('.star', form).each(function( key, value ){
			if ( key <= selectedIndex ) {
				$(value).addClass('active');
			}
		});
	}
});
$(document).on('change', 'input[name=rating]', function(){
	var form = $(this).closest('form');
	$('.star', form).removeClass('active');
	var selected = $('input[name=rating]:checked');
	if ( selected.length > 0 ) {
		var selectedIndex = selected.closest('.star').index();
		$('.star', form).each(function( key, value ){
			if ( key <= selectedIndex ) {
				$(value).addClass('active');
			}
		});
	}
});
$(document).on('change', '.aditional-rate input', function(){
	var label = $(this).data('label');
	$(this).closest('.row').find('.aditional-rate-alt').text( label );
});

$(document).on('click', '.addReview', function(){
	var form = $(this).closest('form');
	form.find('.review-label').removeClass('error');
	form.find('.errors-description').html();
	var errors = '';
	var valid = true;
	if (  $('input[name=rating]:checked', form).length == 0 ) {
		$('input[name=rating]', form).closest('.row').find('.review-label').addClass('error');
		errors = errors + '�� ������ ������� ������.<br>';
		valid = false;
	}
	if (  $('input[name=recom_flag]:checked', form).length == 0 ) {
		$('input[name=recom_flag]', form).closest('.row').find('.review-label').addClass('error');
		errors = errors + '�� ������������ ���� �����?<br>';
		valid = false;
	}
	if ( $('.aditional-block', form).length > 0 ) {
		$('.aditional-rate', form).each(function( key, value ){
			if ( $(value).find('input[type=radio]:checked').length == 0 ) {
				$(value).closest('.row').find('.review-label').addClass('error');
				errors = errors + '�� ������o �������� ��������� "' + $(value).closest('.row').find('.review-label label').text() + '".<br>';
				valid = false;
			}
		});
	}
	if ( $('textarea[name=review]', form).val().length < 15 ) {
		$('textarea[name=review]', form).closest('.row').find('.review-label').addClass('error');
		errors = errors + '����� ������ ������ 15 ��������.<br>';
		valid = false;
	}
	if ( valid ) {
		form.submit();
	} else {
		form.find('.errors-description').html( errors );
	}
});
$(document).on('click', '.yandex_tbl .check_review', function(e) {
	e.preventDefault();
	$.ajax({
		type: "GET",
		url: "reviews_success.php?action=check_review",
		// dataType : "json",
		data: { 
			orders_id: $('#current_orders_id').val(),
			website: $(this).data('website'),
		},
		context: this,
		success: function(){
			$(this).html('��� ����� ����� �������� � ������� 3-5 ����.');
			$(this).addClass('just_send_review');
			$(this).removeClass('check_review');
		},
	});
});
$('form.make_review_form').submit( function(e){
	e.preventDefault();
	var type = $(this).data('type');
	var data = $(this).serializeArray();
	data.push( {'name': 'name', 'value': $(this).find('input[name=review_manual_name]').val() } );
	data.push( {'name': 'manualName', 'value': $(this).find('input[name=review_manual_name]').val() } );
	data.push( {'name': 'type', 'value': type } );
	data.push( {'name': 'rate', 'value': $(this).find('input[name=rating]:checked').val() } );

	$(this).find('.error').empty();
	$.ajax({
		url: '/make_reviews.php?ajax=5&type=' + type,
		data: data,
		type: 'post',
		context: this,
		dataType: 'json',
		success: function( msg ){
			if ( msg['done'] == 1 )
			{
				var answer = '������� �� ��� �����!';
				$(this).html('<div class="thanks">' + answer + '</div>');
			}
		},
	});
});
$('.reviews-write-form').submit(function(e){
	e.preventDefault();
	var data = $(this).serializeArray();
	var postUrl = $(this).attr('action');
	$.ajax({
		url: postUrl + '&ajax=1',
		data: data,
		type: 'post',
		context: this,
		dataType: 'json',
		success: function( msg ){
			if ( msg['done'] == 'done' )
			{
				var answer = '������� �� ��� �����!',
						comment = '��� ����� �������� ����� �������� ������� ���������';
				$(this).html('<div class="thanks">' + answer + '</div><p>'+comment+'</p>');
			}
		},
	});
});