// http://javascript-minifier.com/
var product = new Product({});
var customer = new Customer();
var cookieObj = new Cookie();
var shoppingCart = new ShoppingCart();
var wishlist = new WishList();
var changeCity = new ChangeCity();
// $(document).ready(function(){
	initSearchForm();
	initLogin();
	bootstrapFix();
	initForm();
	initFooter();
	initHeader();
	initReturnList();
	initDeliveryList();
	initSidebar();
	initProduct();
	initProductList();
	initRate();
	initSizesTable();
	initFilterForm();
	initRange();
	initCabinet();
	initHero();
	iitOrderList();
	initListing();
	initLimitOffer();
	init_sc_autocomplete( $('.select-city') );
	initSendDiscountCouponOnEmail();
	initDiscountModalTimer();
	initLoginForm();
	initBottomMobileMenu();

	if( $('.shops-page, .js-shops-page').length ) {
		var shopsWraper = $('.j-info-shop-map');
		if( shopsWraper.data('initOnLoad') == 1 ) {
			initInfoShops( $('.shops-page') );
		}
	}
// });
$(function(){
	initMapPopup();
	initChatHelp();

	function initMapPopup(){
		$('#map-popup').on('click','.ui-menu-item',function(){
			$('.ui-autocomplete-input',$('#map-popup')).val($(this).text())
		});
	}

	function initChatHelp(){
		var initChatChatra = false;
		$('#chat-help-switcher').change(function(){
			if(initChatChatra){
				return true;
			}
			initChat();
		});

		$('.js-open-help-chat').click(function(){
			if(!$('#chat-help-switcher').prop('checked')){
				$('#chat-help-switcher').prop('checked',true).trigger('change');
			}
			$('[for="open-chat-help-switcher"]').trigger('click');
		});
		function initChat() {
			window.ChatraGroupID = 'HQarsacpX5zWsGwfw';
			(function (d, w, c) {
				w.ChatraID = 'gi9TfC2eNNz6WEDNq';
				var s = d.createElement('script');
				w[c] = w[c] || function () {
					(w[c].q = w[c].q || []).push(arguments);
				};
				s.async = true;
				s.src = 'https://call.chatra.io/chatra.js';
				if (d.head) d.head.appendChild(s);
			})(document, window, 'Chatra');

			window.ChatraSetup = {
				mode: 'frame',
				injectTo: 'chatra-wrapper' /* id �����, � ������� ����� ������� ��� */
			};

			$('.js-send-question').click(function () {
				Chatra('sendAutoMessage', $(this).text());
			});
		}
	}
});

function initBottomMobileMenu(){
		var isDesktop = window.innerWidth >1023;
		if(isDesktop){
			return false;
		}
		sendABTest('AB_Mobile_AddBottomMenu');
}

/* viewport width */
function viewport(){
	var e = window,
		a = 'inner';
	if ( !( 'innerWidth' in window ) )
	{
		a = 'client';
		e = document.documentElement || document.body;
	}
	return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
};
/* viewport width */
function checkRigthAside(){
	var fixedEl = $('.fix_holder');
	if( !fixedEl.length ) {
		return false;
	}
	if ( $(window).outerWidth() < 1024 ) {
		return false;
	}
	var productLeftSideEl = $('.product-preview');
	var holder = fixedEl.closest('.product-description');
	var content = holder.find('.fix_description');
	if( productLeftSideEl.height() <= fixedEl.height() ) {
		return false;
	}
	if( fixedEl.hasClass('fixed') ) {
		if( $(window).scrollTop() - 5 <= fixedEl.data('initial-top') ) {
			fixedEl.removeClass('fixed');
		} else {
			if( holder.offset().top + holder.height() - content.height() <  $(window).scrollTop() ) {
				fixedEl.addClass('bottom').removeClass('fixed');
			}
		}
	} else if( fixedEl.hasClass('bottom') ) {
		if( holder.offset().top + holder.height() - content.height() >= $(window).scrollTop() ) {
			fixedEl.addClass('fixed').removeClass('bottom');
		}
	} else {
		if( $(window).scrollTop() + 5 > fixedEl.offset().top ) {
			fixedEl.data('initial-top', fixedEl.offset().top);
			fixedEl.addClass('fixed');
		}
	}
}

function FixedHeader() {
	this.elements = {};
	this.vars = {
		lastScrollTop: $(window).scrollTop(),
		scrolledY: 0, // �� ������� ���������� �����
	};
	this.flags = {
		fixedHeader: false,
		};
	this.elements.headerTop = $('.top_popup-panel');
	this.elements.headerMiddle = $('#header');
	this.updateVars();
};
FixedHeader.prototype.updateVars = function() {
	this.vars.headerTop_height = this.elements.headerTop.outerHeight();
	this.vars.headerMiddle_height = this.elements.headerMiddle.outerHeight();

	this.vars.viewport_wid = viewport().width;
};
if(viewport().width > 1024) {
	FixedHeader.prototype.checkScroll = function (e) {
		if (this.vars.viewport_wid < 1200) {
			return true;
		}
		var st = $(window).scrollTop();
		if (st > this.vars.lastScrollTop) { // ���� ������������ ����
			this.unsetFixedHeader();
			this.vars.scrolledY = 0;
		} else { // ���� ������������ �����
			this.vars.scrolledY += this.vars.lastScrollTop - st;
			if (st > this.vars.headerTop_height) {
				if (this.vars.scrolledY > 400) { // ���� ����������� ������ ��� �� 400px - ��������� �����
					this.setFixedHeader();
				}
			} else {
				this.unsetFixedHeader();
			}
		}
		this.vars.lastScrollTop = st;
	};
	FixedHeader.prototype.setFixedHeader = function () {
		if (this.flags.fixedHeader) {
			return;
		}
		this.elements.headerMiddle.addClass('window-scrolling');
		this.elements.headerTop.css({'margin-bottom': (this.vars.headerMiddle_height) + 'px'});
		this.flags.fixedHeader = true;
	};
	FixedHeader.prototype.unsetFixedHeader = function () {
		if (!this.flags.fixedHeader) {
			return;
		}
		this.elements.headerMiddle.removeClass('window-scrolling');
		this.elements.headerTop.css({'margin-bottom': 0});
		this.flags.fixedHeader = false;
	};

	FixedHeader.prototype.checkResize = function() {
		this.updateVars();
	}

	if( $('.catalog-section').length ) {
		var fixedHeader = new FixedHeader;
		$(window).on('scroll', function( e ) {
			fixedHeader.checkScroll( e );
		});
		$(window).on('resize', function( e ) {
			fixedHeader.checkResize( e );
		});
	}
}
function fixedFilter(){
	var content = $('.control-panel');
	if( content.length && $(document).width() <= 1023 ) {
		var scrollTop = $(window).scrollTop();
		if( content.hasClass('fixed') ) {
			if( scrollTop <= content.data('initial-top') ) {
				content.removeClass('fixed');
			}
		} else if ( scrollTop > content.offset().top ) {
			content.data('initial-top', content.offset().top);
			content.addClass('fixed');
		}
	}
}

$( window ).on('scroll', checkRigthAside);
$( window ).on('scroll', fixedFilter);
var latestKnownScrollY = 0,
	scrollValueY = 0,
	ticking = false;
function requestTick() {
	if(!ticking) {
		requestAnimationFrame(update);
	}
	ticking = true;
}
function update() {
	ticking = false;

	setFixFilters( scrollValueY );
	var content = $('.modal-content .product-description-container');

	if( !content.length ) {
		return false;
	}
	if( content.data('start-position') == undefined ) {
		content.data('start-position', content.position().top);
	}

	var offsetTop = $(window).scrollTop() - content.closest('.modal-content').offset().top + 30; // ������� ������
	var start_position = content.data('start-position');
	var modalHeight = content.closest('.modal-content').height();
	var modalContentHeight = content.closest('.modal-product-item').height();
	var contentHeight = content.height();
	if( contentHeight >= modalContentHeight ) {
		content.css('position', 'static');
	} else if( offsetTop >= start_position ) {
		content.css('position', 'absolute');
		offsetTop = offsetTop < 0 ? offsetTop * -1 : offsetTop;
		var contentBottom = contentHeight + offsetTop;
		if( contentBottom >= modalHeight ) {
			offsetTop-= contentBottom - modalHeight;
		}
		content.css('top', offsetTop + 'px' );
	} else {
		content.css('position', 'absolute');
		content.css('top', start_position+'px');
	}
}

$(window).on('scroll', function(e) {
	scrollValueY = window.scrollY - latestKnownScrollY;
	latestKnownScrollY = window.scrollY;
	requestTick();
});

$('#product-preview').on('scroll', function() {
	latestKnownScrollY = window.scrollY;
	requestTick();
});
$(document).on('change', '.j-select-size-type', '#fsize .size-select', function(){
	var pickType = $(this).val();
	$(this).closest('.size-select').next('ul.js-size-pick').find('li[data-value='+pickType+']').addClass('active').siblings('li').removeClass('active');

});
$('.side-menu-list-new').on('click', '.has-drop', function( e ) {
	e.preventDefault();
	$(this).toggleClass('opened').siblings('.has-drop').removeClass('opened');
});

if ( $('#popup_500_5day').length ) {
	if ( $('#popup_500_5day.autoOpen').length ) {
		$('#popup_500_5day').modal('show');
	}
	$(document).on('click', '.j-500-opener', function(e){
		e.preventDefault();
		$('#popup_500_5day').modal('show');
	});
}
if ( $('#message_from_support-popup').length ) {
	$('#message_from_support-popup').modal('show');
}
$('#sizes').on('show.bs.modal', function(e){
	$(e.relatedTarget).closest('.product-description-container').find('.sized-description-form').submit();
});
$('#sizes').on('shown.bs.modal', function(e){
	initSizesTable();
});
$('.j-show-modal-onload').modal('show');
$('#coupon__5day').click(function(e){
	e.preventDefault();

	var popup = $(this).closest('.modal-body');
	if( $(this).hasClass('close-500') ){
		$("#popup_500_5day").modal('hide');

		var telephone = popup.find('input[name=telephone]').val();
		var firstname = popup.find('input[name=firstname]').val();
		var customers_id = popup.find('input[name=customers_id]').val();
		if( customers_id.length && (telephone.length || firstname.length) ) {
			$.ajax({
			url: '/sent_coupon_500_5day.php',
				data : {
					update : true,
					customers_id : customers_id,
					telephone : telephone,
					firstname : firstname,
					ajax : 1,
				},
			});
		}
		return true;
	}
	var input = popup.find('input[name=email]');
	var email = input.val();
	var btn = $(this);
	if( email.length ){
		$.ajax({
			url: '/sent_coupon_500_5day.php',
			data : {
				email : email,
				ajax : 1,
			},
			dataType: 'json',
			success: function(msg){
				if(msg['err_mes'] != undefined ){
					input.val('');
				} else {
					popup.find('h2').html('�������!').css('height','unset');;
					popup.find('.js-switch-text').each(function() {
						$(this).html( $(this).data('text-to-switch') );
					});
					popup.find('.siver_half span.js-switch-text').addClass('green');
					popup.find('.holder.more_info').show();
					popup.find('.siver_half span:not(.placeholder)').css('padding','8px');
					popup.find('input[name=customers_id]').val(msg['customers_id']);
					input.closest('.holder').hide();
					btn.html('�������� � ������� � ��������!');
					btn.addClass('close-500');
					popup.find('input[name=firstname]').focus();
					customer.mailCreateSuccess( msg );
					return true;
				}
			}
		});
		return false;
	} else {
		input.css('border','1px solid red');
		return false;
	}

});

$('#popup_500_5day').on('keydown', 'input' , function(e) {
	if(e.keyCode === 13) {
		$('#coupon__5day').trigger('click');
	}
});

$('.tabset').tabset();

$('.delivery-list, #check-delivery-product').on('click', '.btn-see-map', function( e, delivery ) {
	e.preventDefault();
	var form = $(this).closest('form');
	$('input[name=filtrDelivery]', form).val( delivery == undefined ? '' : delivery );
	var tmp_options = form.serializeArray();
	var options = {};
	for (var i = 0; i < tmp_options.length; i++) {
		options[ tmp_options[i].name ] = tmp_options[i].value;
	}
	$('#see-map').trigger('click');
	ymaps.ready(function() {
		$('#map-popup').shopsMap( options );
	});
});

$('table.checkClickRow tr').click(function(e){
	if(e.target.tagName == 'TD'){
		$(this).find('td:eq(0) input[type=checkbox]').trigger('click');
	}
});

$('.default-ajax-form').ajaxForm( default_ajax_form_options );

function iitOrderList() {
	var animationSpeed = 700;

	$('.order-steps-list > li').each(function() {
		if(!$(this).hasClass('open')){
			$(this).find('.expanded').hide();
		}
		if(!$(this).hasClass('passed')){
			$(this).find('.expanded-passed').hide();
		}
	});


	$('input[data-tab]').on('change', function() {
		var tabName = $(this).data('tab');
		$('.group-expanded').each(function() {
			if($(this).data('tab') == tabName && !$(this).hasClass('active')){
				$(this)
					.addClass('active')
					.stop()
					.slideDown(animationSpeed)
					.siblings('.group-expanded.active')
					.removeClass('active')
					.stop()
					.slideUp(animationSpeed);
				$(this).find('[data-required="true"]').each(function() {
					$(this).attr('required', 'required');
				});

				$(this).siblings('.group-expanded.active').find('[data-required="true"]').each(function() {
					$(this).removeAttr('required');
				});
			} else if($(this).data('tab') != tabName && $(this).hasClass('active')) {
				$(this)
					.removeClass('active')
					.stop()
					.slideUp(animationSpeed);

				$(this).find('[data-required="true"]').each(function() {
					$(this).removeAttr('required');
				});
			}
		});
	});

	if($('.delivery-radio-list').length){
		$('.delivery-radio-list input[data-tab]').each(function() {
			if($(this).prop('checked')){
				$(this).change();
				return false;
			}
		});
	}

	$('.group-expanded:not(.active)').hide();

	$('.next-step-btn').on('click', function(e) {
		e.preventDefault();
		var hasInvalid = false;
		$(this).closest('.expanded').find('input').each(function() {
			if( (!$(this).closest('.group-expanded').length) || ($(this).closest('.group-expanded').length && $(this).closest('.group-expanded').is(':visible')) ){
				if($(this).is(':invalid')){
					hasInvalid = true;
					$(this).focus();
					return false;
				}
			}
		});
		if(!hasInvalid){
			$(this)
				.closest('li.open')
				.find('> .expanded')
				.stop()
				.slideUp();
			if($(this).closest('li.open').next('li').length){
				$(this)
					.closest('li.open')
					.find('.expanded-passed')
					.stop()
					.slideDown(animationSpeed);
				$(this)
					.closest('li.open')
					.removeClass('open')
					.addClass('passed')
					.next('li')
					.addClass('open')
					.find('> .expanded')
					.stop()
					.slideDown(animationSpeed, function() {
						var dest = $('.order-steps-list>li.open').offset().top;
						$('html, body').stop().animate({
							scrollTop:dest
						}, 350);
					});
			}
		}
	});


	$('.order-steps-list .btn-change').on('click', function(e) {
		e.preventDefault();
		$(this)
			.closest('li')
			.removeClass('passed')
			.addClass('open')
			.find('> .expanded')
			.stop()
			.slideDown(animationSpeed);
		$(this)
			.closest('li')
			.find('.expanded-passed')
			.stop()
			.slideUp(animationSpeed);
		$(this)
			.closest('li')
			.nextAll('li')
			.removeClass('open')
			.removeClass('passed')
			.find('> .expanded')
			.stop()
			.slideUp(animationSpeed);
		$(this)
			.closest('li')
			.nextAll('li')
			.find('.expanded-passed')
			.stop()
			.slideUp(animationSpeed, function() {
				var dest = $('.order-steps-list>li.open').offset().top;
				$('html, body').stop().animate({
					scrollTop:dest
				}, 350);
			});
	});


	$('.select-pay-list .list-expanded').hide();
	$('.select-pay-list .more').on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass('active').siblings('.list-expanded').stop().slideToggle();
	});


};

function initHero() {
	$('.heroSlider').slick({
		dots:true,
		fade:true
	});

	$('.js-productbanners-slider').slick({
		slidesToShow:5,
		infinite:true,
		dots:true,
		responsive:[
			{
				breakpoint:1024,
				settings:{
					slidesToShow:4,
					arrows:false
				}
			},
			{
				breakpoint:768,
				settings:{
					arrows:false,
					slidesToShow:1
				}
			}
		]
	});
};

function initCabinet() {
	$('.cabinet-section .panel-heading').on('click', function() {
		$(this).closest('.panel').toggleClass('open');
		if($(this).closest('.panel').hasClass('open')){
			$(this).closest('.panel').find('.panel-body').stop().slideDown();
		} else {
			$(this).closest('.panel').find('.panel-body').stop().slideUp();
		}
	});


	$('.order-table .details').hide();

	$('.order-table .btn').on('click', function(e) {
		e.preventDefault();
		if(!$(this).closest('tr').next('.details').hasClass('open')){
			$(this).closest('tr').siblings('.details.open').removeClass('open').stop().hide();
		}
		$(this).closest('tr').next('.details').toggleClass('open').stop().toggle();
	});

	if($('.block_stages_order').length){
		var index = $('.line_staget_order_slide.active').index();
		$('.block_stages_order').slick({
			// dots: true,
			arrows: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			focusOnSelect: true,
			autoplay: false,
			autoplaySpeed: 7000,
			fade: false,
			infinite: false,
			cssEase: 'ease-in-out',
			centerMode: true,
			initialSlide: index,
			arrows: false,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						arrows: false,
						dots: false,
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						dots: false,
						slidesToShow: 2,
						centerMode: false,
					}
				}
			]
		});
	}

	// BOF ACCOUNT_PASSWORD
	$(document).on('submit', '.change-pass-form', function(e) {
		$('.error', this).text('');
		$(this).find('input[type=password]').each(function() {
			if( this.value.length < 5 ) {
				$(this).parent().find('.error').text('������ ������ ��������� ��� ������� 5 ��������.');
				e.preventDefault();
			}
		});
		var pass_new_el = $(this).find('[name=password_new]');
		var pass_confirm_el = $(this).find('[name=password_confirmation]');
		if( pass_new_el.val() != pass_confirm_el.val() ) {
			pass_new_el.parent().find('.error').text('���� ����������� ������ � ����� ������ ������ ���������.');
			pass_confirm_el.parent().find('.error').text('���� ����������� ������ � ����� ������ ������ ���������.');
		}
	} );
	// EOF ACCOUNT_PASSWORD

	$(document).on('submit', '#account_history_info-cancel form', function(e) {
		$('#account_history_info-cancel').modal('hide').one('hidden.bs.modal', function() {
			$('#account_history_info-cancel-success').modal('show');
		});
	});

};

function initFilterForm() {
	$('.filter-item').each(function() {
		if(!$(this).hasClass('open')){
			$(this).find('.expanded').hide();
		}
	});
	$('.filter-item .title').on('click', function() {
		if($(this).closest('.js-content-specials').length && window.innerWidth>1023){
			$(this).closest('.js-content-specials').find('.filter-item .expanded').fadeOut();
		}
		$(this)
			.closest('.filter-item')
			.toggleClass('open')
			.find('.expanded')
			.stop()
			.slideToggle({
				complete: function(){
					setFixFilters( 0 );
				},
				progress: function() {
					setFixFilters( 0 );
				},
			});
	});

	$('.catalog-top-panel .show-filter').on('click', function(e) {
		e.preventDefault();
		$('body').addClass('filter-open');
	});

	$('.filter-sidebar .heading-panel .close, .filter-btns .j-close-filters').on('click', function(e) {
		e.preventDefault();
		$('body').removeClass('filter-open');
	});
};

function initRange() {
	$( ".slider-range" ).each(function(){
		var _slider = $(this);
		var _values = $('.min_max_currentmin_currentmax',_slider).val().split('/');
		_slider.slider({
			range: true,
			min: parseInt(_values[0]),
			max: parseInt(_values[1]),
			step:1,
			values: [ parseInt(_values[2]), parseInt(_values[3]) ],
			slide: function(event, ui) {
				_slider.closest('.range-slider').find( ".from" ).val(addSpaces(ui.values[0]));
				_slider.closest('.range-slider').find( ".to").val(addSpaces(ui.values[1]));
			},
			change: function(event, ui) {
				if( event.bubbles ) {
					window.listing.info.prices = [0,0];
				}
				_slider.closest('.range-slider').find( ".from" ).val(addSpaces(ui.values[0]));
				_slider.closest('.range-slider').find( ".to").val(addSpaces(ui.values[1]));

				if($(this).closest('.filter-form').length){
					var self = this;
					var value =$(this).closest('.range-slider').find('.from').val() + ' - ' + $(this).closest('.range-slider').find('.to').val();
					$(this).closest('li').addClass('selected').find('.heading .value-holder span').text(value);
					$(this).closest('li').addClass('selected').find('.heading input[type="hidden"]').val(value);
				}
				if( event.bubbles ) {
					$( "#amount" ).change();
				}
			}
		});
		$( ".ui-slider-handle",_slider).html("<span>"+_slider.slider('values',1)+"</span>");
		$( ".ui-slider-handle" ,_slider).eq(0).find('span').text(_slider.slider('values',0));
		_slider.closest('.range-slider').find( ".to").val(addSpaces(_slider.slider('values',1)));
		_slider.closest('.range-slider').find( ".from" ).val(addSpaces(_slider.slider('values',0)));
	});

	$("input.from").on('change', function(){
		window.listing.info.prices = [0,0];
		var value1=removeSpaces($(this).val());
		var value2=removeSpaces($(this).closest('.range-slider').find("input.to").val());
		if(value1 > value2){
			value1 = value2;
			$(this).val(value1);
		}
		$(this).closest('.range-slider').find(".slider-range").slider("values",0,value1);
	});
	$("input.to").on('change', function(){
		window.listing.info.prices = [0,0];
		var maxValue = parseInt($(this).closest('.range-slider').find(".min_max_currentmin_currentmax").val().split('/')[1]);
		var value1=removeSpaces($(this).closest('.range-slider').find("input.from").val());
		var value2=removeSpaces($(this).val());
		if(value2 > maxValue){
			value2 = maxValue;
			$(this).val(maxValue);
		}
		if(value1 > value2){
			value2 = value1;
			$(this).val(value2);
		}
		$(this).closest('.range-slider').find(".slider-range").slider("values",1,value2);
	});

	function addSpaces(n) {
		n = parseInt(n);
		return n;
		return (n + "").split("").reverse().join("").replace(/(\d{3})/g, "$1 ").split("").reverse().join("").replace(/^ /, "");
	};
	function removeSpaces(n) {
		n = n.split(' ');
		var result = "";
		n.forEach(function(elem, i) {
			result = result + n[i];
		});
		return parseInt(result);
	};
};

function initListing(initListingOption) {
	initPrevPagePagination();
	if( $('#filtr-form').length == 0 ) {
		return;
	}
	window.select_form_start_position = $('.js-catalog-top-panel').offset().top - 10;
	window.listing = new Listing();
	window.listing.parseOptions();

	var filterForm = $('#filtr-form');
	var filterFormHolder = $('>.filter-container', filterForm);

	var productsWrap = $('.catalog-section .product-list');
	var productsWrapDynamic = $('.catalog-section .product-list.dynamic');

	//���� ���������� ������ ����� ������ �������� - �� ����������� ��� ������� �������� � ������ �����
	window.addEventListener('popstate', function (event) {
		var filters = event.state;

		if($(filterForm)) {
			setFilterCheckboxes(filters);
			renderGoods(filterForm);
		}

		function renderGoods(form){
			$.ajax({
				url: window.location.pathname+window.location.search+'&ajax=3',
				dataType: 'json',
				type: 'GET',
				success: function(responce){
					listing.settings.formShowResponse(responce,{},{},form);
				},
			});
		}

		function setFilterCheckboxes (filters){
			$('input:checkbox', filterForm).prop('checked',false);

			for (var i=0, sizeI = filters.length; i<sizeI; i++){
				var filtersValues = filters[i].value.split(','),
					filterName = filters[i].name;

				for (var j=0, sizeJ = filtersValues.length; j<sizeJ; j++){
					var inputName = 'input:checkbox[data-for="'+filterName+'"][value="'+filtersValues[j]+'"]';
					$(inputName).prop('checked',true);
				}
			}
		}
	});

	$('input:checkbox:not(.js-switcher)', filterForm).on('change', function(e) {
		listing.setMultiselectHiddenValue( this );
	});

	$('.j-listing-sort-block li').on('click', function(e){
		e.preventDefault();
		if( !$(this).hasClass('selected') ) {
			$(this).addClass('selected').siblings().removeClass('selected');
			$('input[name=fsort]', filterForm).val( $(this).data('value') ).trigger('change');
			var text = $(this).find('a').text();
			$(this).closest('.j-listing-sort-block').find('.j-selected-text').text(text);
		}
		setTimeout(function(){
			$('.j-listing-sort-block').removeClass('open');
			$('.j-listing-sort-block .dropdown-toggle').attr('aria-expanded', 'false');
		},100);
	});

	//pagination shop control
	$('.catalog-section .pagination-page-list a').click(function(){
		changePageOfGoods( $(this).data('page') || 1)
		return false;
	});

	/**
	 * ������� �� ������ �������� � ���������
	 * @param page
	 */
	function changePageOfGoods(page){
		setPageOfPagination(page);
		$('html, body').animate({scrollTop: 0}, 600);
		$('.product-list.dynamic').html(getPreloaderBody());
		$('.loadmore').hide();
		$('.pagination-page-list').hide();
		setTimeout(function () {
			$('.loadmore').show();
			$('.pagination-page-list').show()
		}, 6000);
		$(filterForm).submit();
	}

	function setPageOfPagination(page){
		page = page || 1;
		$('input[name=page]', filterForm).val(page || 1);
		$('.pagination-page-list li').removeClass('active');
		$('.pagination-page-list a[data-page="' + page + '"]').parent().addClass('active');
		if(page == 1){

		}
	}
	if(!(initListingOption && initListingOption.notInitFilterFormChange)) {
		filterForm.on('change', function (e) {
			$('.js-catalog-description').fadeOut();
			if (!$(e.target).hasClass('j-select-size-type') && !$(e.target).hasClass('js-switcher')) {
				$('input[name=page]', filterForm).val(1);
				$(this).submit();
				initListing({notInitFilterFormChange: true});
			}
		});
	}

	$('.loadmore a').on('click', function(e) {
		e.preventDefault();
		$('#show_more').val( 1 );

		var newPage = parseInt($('input[name=page]', filterForm).val()) + 1

		setPageOfPagination(newPage);
		$(this).addClass('loading');
		$('#cube-loader').remove();
		filterForm.submit();
	});
	$('.j-show-filters').on('click', function(e) {
		e.preventDefault();
		$('.catalog-filters-section').toggleClass('show');
	});

	filterForm.on('click', '.filter-box.active .filter-title', function(e) {
		listing.clearFiltr( this );
	});
	listing.hideFiltrs();
	$('.hidden-filtr-toggler').click(function( e ) {
		e.preventDefault();
		listing.toggleHiddenFiltrs();
	});

	productsWrap.on('mouseenter', '>.product-list-item', function(e) {
		if ( $(document).width() <= 1023 ) return;
		$(this).addClass('hover');
	});
	productsWrap.on('mouseleave', '>.product-list-item', function(e) {
		if ( $(document).width() <= 1023 ) return;
		$(this).removeClass('hover');
	});

	productsWrap.on('mouseenter.listingOtherColor', '.product-list-item .color-picker>li', function() {
		productInfo = $(this).data('colorinfo');
		productEl = $(this).closest('.product-list-item');
		productEl[ productInfo.disPct > 0 || ( productInfo.coupon_price > 0 && productInfo.coupon_price < parseInt( productInfo.cprice ) ) ? 'addClass' : 'removeClass' ]('sale');
		var href = '/product/' + productInfo.uriName + productInfo.fsizeQuery;
		$('.inner .visual>a', productEl).attr('href', href);
		// badges - start
		$('.inner .visual .badges', productEl).empty();
		if ( productInfo.coupon_price > 0 && productInfo.coupon_price < parseInt( productInfo.cprice ) ) {
			$('.inner .visual .badges', productEl).append('<span class="badge">-' + productInfo.coupon_pct + '%</span>');
		} else if( productInfo.disPct > 0 ) {
			$('.inner .visual .badges', productEl).append('<span class="badge">-' + productInfo.disPct + '%</span>');
		}
		// badges - end
		$('.inner .visual .js-product-link img', productEl).attr('src', productInfo.firstSrc);
		$('.inner .visual .btn-panel .j-fast-view-btn', productEl).data('href', href);
		// wishlist - start
		var inWishlist = window.wishlist.list.indexOf( productInfo.id ) != -1 ? 1 : 0 ;
		var wishlistBtn = $('.inner .visual .btn-panel .j-wishlist-add', productEl);
		wishlistBtn.data('products_id', productInfo.id);
		wishlistBtn.data('wishlist', inWishlist);
		wishlistBtn[ inWishlist ? 'addClass' : 'removeClass' ]('active');
		// wishlist - end
		$('.inner .product-description .article', productEl).text( productInfo.name );
		if ( productInfo.coupon_price > 0 && productInfo.coupon_price < parseInt(productInfo.cprice) ) {
			var priceWithDiscountText = productInfo.coupon_price_text + '<span class="old-price">' + productInfo.price + '</span><span class="pct">(-' + productInfo.coupon_pct + '%)</span>';

			if ( false && productInfo.product_price_history.length ) {
				priceWithDiscountText += '<br><span class="coupon-price-label">(� ������ �����)</span>';
				priceWithDiscountText += '<div class="discount-description">';
				for(var discount in productInfo.product_price_history){
					let item = productInfo.product_price_history[discount];

					priceWithDiscountText += '<div>';
					priceWithDiscountText += '<span class="discount-description-title">'+ item.name + '</span>';
					priceWithDiscountText += '<span class="discount-description-diff">'+ (item.after - item.before) + ' ���</span>';
					priceWithDiscountText += '</div>';
				}
				priceWithDiscountText += '</div>';
			}
			$('.inner .price', productEl).html( priceWithDiscountText );
		} else if( productInfo.disPct > 0 ) {
			if(productInfo.coupon_pct){
				$('.inner .price', productEl).html(productInfo.coupon_price_text + '<span class="old-price">' + productInfo.price + '</span><span class="pct">(-' + productInfo.disPct + '%)</span>');
			}else {
				$('.inner .price', productEl).html(productInfo.cprice + '<span class="old-price">' + productInfo.price + '</span><span class="pct">(-' + productInfo.disPct + '%)</span>');
			}
		} else {
			$('.inner .price', productEl).html( productInfo.price );
		}
		renderProductSize(productEl,productInfo);

		function renderProductSize(productEl,productInfo){
			$('.hover_size [name="products_id"]',productEl).val(productInfo.id).attr('value',productInfo.id);

			var sizesDom = $('.hover_size .sizes',productEl);

			if(!sizesDom){
				return false;
			}

			var sizesData = productInfo.sizes,
					newHtml = '';

			if(!sizesData.length){
				$(sizesDom).html(newHtml);
				return false;
			}

			for(var size of sizesData){
				if(!size.id || !size.text){
					continue;
				}
				newHtml += '<li><label><input type="radio" name="id[1]" value="'+size.id+'" style="display:none;">'+size.text+'</label></li>';
			}

			$(sizesDom).html(newHtml);
		}
	});
	productsWrap.on('mouseleave.listingOtherColor', '.product-list-item', function(e) {
		$('.color-picker>li', this).eq(0).trigger('mouseenter.listingOtherColor');
	});
	$('.filter-btns .btn-reset').click( function(e) { // �������� ��� �������
		e.preventDefault();
		listing.clearForm();
	} );
	// $(document).on('scroll', function() {
	// 	var pageNumber = parseInt($('input[name=page]', filterForm).val());
	// 	var pagesCount = parseInt(filterForm.data('pagesCount'));
	// 	if( pageNumber < pagesCount ) {
	// 		var listBottomPosition = productsWrapDynamic.offset().top + productsWrapDynamic.height(); // ������ ���������� ������ �������
	// 		var windowBottomPosition = $(window).scrollTop() + $(window).height(); // ������ ���������� ������
	// 		if( listBottomPosition - windowBottomPosition < 200 ) { // ���� �� ����� ������ ������������ 200px
	// 			$('#show_more').val(1);
	// 			$('input[name=page]', filterForm).val( pageNumber + 1 );
	// 			filterForm.submit();
	// 		}
	// 	}
	// });

	function initPrevPagePagination(){
		$('.js-go-prev-page').click(function(){
			var prevPage = $(this).attr('data-page');

			if(1 === +prevPage){
				$(this).addClass('hidden');
			}else{
				$(this).attr('data-page', prevPage - 1 );
			}
			$('')
			$('#filtr-form [name="page"]').val(prevPage);
			$('#filtr-form').submit();
		})
	}
}

function getPreloaderBody(){
	return '<li style="width:100%;">' +
		'	<div id="cube-loader">\n' +
		'    <div class="caption">\n' +
		'      <div class="cube-loader">\n' +
		'        <div class="cube loader-1"></div>\n' +
		'        <div class="cube loader-2"></div>\n' +
		'        <div class="cube loader-4"></div>\n' +
		'        <div class="cube loader-3"></div>\n' +
		'      </div>\n' +
		'    </div>\n' +
		'  </div>' +
		'</li>';
}

function initSizesTable() {
	$('.sizes-table table').on('mouseenter', 'td', function(e) {
		$(this).prevAll('td').addClass('active');
		var index = $(this).index();
		if(index > 0){
			$(this).closest('tr').prevAll('tr').each(function() {
				$(this).find('td').eq(index).addClass('active');
			});
			$(this).closest('table').find('thead th').eq(index).addClass('active');
		}
	});
	$('.sizes-table table').on('mouseleave', 'td', function(e) {
		$(this).prevAll('td').removeClass('active');
		var index = $(this).index();
		if(index > 0){
			$(this).closest('tr').prevAll('tr').each(function() {
				$(this).find('td').eq(index).removeClass('active');
			});
			$(this).closest('table').find('thead th').eq(index).removeClass('active');
		}
	});
};

function initRate() {
	$(".rate").each(function() {
		if($(this).data('value') >= 0){
			var value = parseFloat($(this).data('value')),
				width = $(this).data('width'),
				startColor = $(this).data('start-color'),
				readOnly = $(this).data('disabled') === true ? true : false;
				color = $(this).hasClass('red') ? '#d71920' : '#000';
			$(this).rateYo({
				rating:value,
				normalFill:startColor,
				ratedFill:color,
				starWidth:width,
				spacing:'6px',
				fullStar:true,
				readOnly:readOnly
			});
		}
	});
};

function initFastViewProduct() {
	var modalFrame = $('#product-preview');
	modalFrame.imagesLoaded(function() {
		$('.product-preview-slider', modalFrame).slick({
			arrows:false,
			asNavFor:'#product-preview .product-preview-switcher'
		});
		$('.product-preview-switcher', modalFrame).slick({
			vertical:true,
			slidesToShow:3,
			focusOnSelect:true,
			asNavFor:'#product-preview .product-preview-slider'
		});
		if ( $('#j-available-timer-fast', modalFrame).length ) {
			let end_date = {
				"full_year": $('#j-available-timer-fast').data('year'),
				"month": $('#j-available-timer-fast').data('month'),
				"day": $('#j-available-timer-fast').data('day'),
				"hours": $('#j-available-timer-fast').data('hours'),
				"minutes": $('#j-available-timer-fast').data('minutes'),
				"seconds": $('#j-available-timer-fast').data('seconds')
			};
			setTimer(end_date, '#j-available-timer-fast', '#product-timer-holder-fast');
		}
	});
	initForm( modalFrame );
}

function initDeliveryList() {
	$('.delivery-list > li').each(function() {
		if(!$(this).hasClass('open')){
			$(this).find('.expanded').hide();
		}
	});
	$('.delivery-list .more a').on('click', function(e) {
		e.preventDefault();
		if(!$(this).closest('li').hasClass('open')){
			$(this)
				.closest('li')
				.siblings('li.open')
				.find('.expanded')
				.stop()
				.slideUp(350);
		}
		$(this)
			.closest('li')
			.toggleClass('open')
			.siblings('li.open')
			.removeClass('open');
		if($(this).closest('li').hasClass('open')){
			var self = this;
			$(this).closest('li').find('.expanded').stop().slideDown(350, function() {
				var dest = $(self).closest('li').offset().top;
				$('html, body').stop().animate({
					scrollTop:dest
				}, 500);
			});
		} else {
			$(this).closest('li').find('.expanded').stop().slideUp(350);
		}
	});
};

function initReturnList() {
	$('.returns-step-list > li').each(function() {
		if(!$(this).hasClass('open')){
			$(this).find('.expanded').hide();
		}
	});
	$('.returns-step-list .more a').on('click', function(e) {
		e.preventDefault();
		if(!$(this).closest('li').hasClass('open')){
			$(this)
				.closest('li')
				.siblings('li.open')
				.find('.expanded')
				.stop()
				.slideUp(350);
		}
		$(this)
			.closest('li')
			.toggleClass('open')
			.siblings('li.open')
			.removeClass('open');
		if($(this).closest('li').hasClass('open')){
			var self = this;
			$(this).closest('li').find('.expanded').stop().slideDown(350, function() {
				var dest = $(self).closest('li').offset().top;
				$('html, body').stop().animate({
					scrollTop:dest
				}, 500);
			});
		} else {
			$(this).closest('li').find('.expanded').stop().slideUp(350);
		}
	});
};

function initNoveltiesList() {
};

function initFooter() {
	$('.full-version a').on('click', function(e) {
		e.preventDefault();
		$('body').addClass('desktop-version');
		document.getElementsByName("viewport")[0].setAttribute('content', 'width=1280, inital-scale=1.0');
		$(window).resize();
		$('.slick-slider').each(function() {
			$(this).slick('setPosition');
		});
		setTimeout(function() {
			$('.slick-slider').each(function() {
				$(this).slick('setPosition');
			});
			$(window).resize();
		}, 500);
	});
};

function initForm( wraper ) {
	if( wraper == undefined ) {
		wraper = $(document);
	}
	$('.phone-11-dig').phoneMask();
	$("input[data-mask]", wraper).each(function() {
		var mask = $(this).data('mask');
		$(this).mask(mask, {
			placeholder: '_'
		});
	});
	$('.default-validate-form', wraper).each(function() {
		$(this).validate({
			onChange : $(this).data('validateOnChange') != undefined
		});
	});
};

function initSearchForm() {
	$('.navbar-form .form-control').on('focus', function() {
		$(this).closest('.navbar-form').addClass('focused');
		$('body').removeClass('menu-open');
	});
	$('.navbar-form .form-control').on('blur', function(e) {
		if(!$(this).val()){
			// $(this).closest('.navbar-form').removeClass('focused');
		}
	});
	$('.navbar-form .search-btn').on('click', function(e) {
		if(!$(this).closest('.navbar-form').find('.form-control').val()){
			e.preventDefault();
			$(this).closest('.navbar-form').find('.form-control').focus();
		}
	});
	$('.navbar-form button[type="reset"]').on('click', function() {
		$(this).closest('.navbar-form').removeClass('focused');
	});
	$('.navbar-form').on('submit', function(e){
		if ( window.outerWidth <= 1024 ) {
			$('form.quick_find .div-loading').show();
		}
	});
};



function initInfoShops( wrapper, useWrapper ) {
	ymaps.ready(function() {
		var shopsWraper;

		if(useWrapper){
			shopsWraper = wrapper;
		}else{
			shopsWraper = $('.j-info-shop-map', wrapper);
		}
		if( shopsWraper.data('initialized') == 1 ) {
			return;
		}
		shopsWraper.data('initialized', 1);
		var city = $('select[name=shop_city]', shopsWraper).val();
		var mapElement = $('#map', shopsWraper);
		var map = new ymaps.Map(mapElement[0],
			ymaps.util.bounds.getCenterAndZoom(
				$('.j-info-shop-map-wraper', shopsWraper).data('start'),
				[mapElement.width(), mapElement.height()]
			));
		map.behaviors.enable(["default"]);
		map.behaviors.disable(["scrollZoom", "rightMouseButtonMagnifier"/*, "dblClickZoom", "MultiTouch"*/]);
		map.controls.add("zoomControl");
		map.controls.add("typeSelector");
		map.controls.remove("fullscreenControl");
		var infoShopsOptions = {
			city: city,
			uri: shopsWraper.data('optionsUri'),
			hasList: shopsWraper.data('optionsList'),
			openBalloon: shopsWraper.data('openBalloon'),
			wrapper: shopsWraper,
			listWraper: $('.j-info-shop-list', shopsWraper),
			listScrollWraper: $('.j-info-shop-list', shopsWraper),
			//listItem: $('.j-info-shop-list-item', shopsWraper),
			afterUpdateListCallback: function() {
				setInfoShopsListHandlers( this );
			},
			afterOpenListItemCallback: function(id) {
				var banners = [];
				var shop = infoShops.points[ infoShops.city ].list[ id ];
				var sliderItem = $('.j-info-shop-map .heroSlider');
				if ( sliderItem.length ) {
					var images = shop.images;
					$.each(images, function(k,image) {
						banners.push({
							b: image,
							s: image,
							name: shop.shop_name,
							address: shop.shop_address,
						});
					});
					infoShopsShowBanners( banners );
				}
			}
		};
		if( shopsWraper.data('url') ) {
			infoShopsOptions.url = shopsWraper.data('url');
		}
		if( shopsWraper.data('ajaxActionParam') ) {
			infoShopsOptions.ajaxActionParam = shopsWraper.data('ajaxActionParam');
		}
		var infoShops = new InfoShops( infoShopsOptions );
		infoShops.setMap( map );
		$(shopsWraper).on('click', '.j-info-shop-list-item', function(e) {
			if ( $(e.target).hasClass('closeBtn') ) return;
			if( !$(this).hasClass('active') ) {
				infoShops.openListItem( $(this).data('id') );
				infoShops.setPointUri( $(this).data('id') );
				infoShops.openPlacemark( $(this).data('id') );
				infoShops.setStaticMap( $(this).data('id') );
				// $("html, body").animate({scrollTop: $('#info-shops-map').offset().top});
			}
		});
		$(shopsWraper).on('click', '.j-info-shop-item-banner', function(e) { // ���� �� ������� �������� - ��������� �������
			var el = $('.j-info-shop-list-item[data-id="' + $(this).data('info_shop_id') + '"]', shopsWraper);
			el.trigger('click');
		});

		$(shopsWraper).on('change', 'select[name=shop_city]', function() {
			var loader = $(this).closest('.box-field');
			loader.addClass('loading');
			infoShops.setCity( $(this).val() ).done(function() {
				infoShops.setCityUri();
				loader.removeClass('loading');
				$('.shops-title-right span').text( $('.shops-list.j-info-shop-list li').length );
			});
		});

		$(shopsWraper).on('change', 'select[name=metro]', function() {
			if( $(this).val().trim().length ) {
				infoShops.sortList('distance', {'fromMetro': $(this).val()});
			} else {
				infoShops.sortList('id');
			}
			$('.j-info-shop-list').scrollTop(0);
		});

		$(shopsWraper).on('click', '.show-shops', function(){
			$('select[name=shop_city]', shopsWraper).trigger('change');
		});

		$(shopsWraper).on('click', '.show-all-shops', function(e) {
			e.preventDefault();
			if( $('select[name=shop_city]').val()!='all' ) {
				$('select[name=shop_city]').val('all').trigger('change');
			}
		});

		$(shopsWraper).on('change', 'input[name=onlyBrand]', function(){
			if ( $(this).prop('checked') ) {
				$('.otherShops').hide();
			} else {
				$('.otherShops').show();
			}
			var currCity = $('select[name=shop_city]', shopsWraper).val();
			infoShops.putPointsForCity( currCity, false );
		});

		var optiosnSelect = $('select.j-info-shop-product-size', shopsWraper);
		if( optiosnSelect.length ) {
			optiosnSelect.on('change', function() {
				infoShops.setAjaxParam($(this).attr('name'), $(this).val());
				infoShops.refreshCities();
			});
			if( optiosnSelect.val().length ) {
				infoShops.setAjaxParam(optiosnSelect.attr('name'), optiosnSelect.val());
			}
		}

		infoShops.getListByCity( city ).done(function(points){
			infoShops.putPointsForCity( city, false );
			// infoShops.setStaticMap();
			// infoShops.updateList( points.list );
		});
		// setInfoShopsListHandlers( infoShops );
		function setInfoShopsListHandlers( infoShops ) {
			var banners = [];
			if( infoShops.city != 'all' ) {
				var city = infoShops.points[ infoShops.city ];
				var cityInfo = city.city;
				var list = city.list;
				$.each(list, function(k,shop) {
					if( shop.mainBanner.length ) {
						banners.push({
							b: shop.mainBanner,
							s: shop.mainBanner,
							name: shop.shop_name + ' � �. ' + cityInfo.city,
							address: shop.shop_address,
							info_shop_id: shop.info_shop_id,
						});
					}
				});
				infoShopsShowBanners( banners, cityInfo );
			} else {
				infoShopsShowBanners( [] );
			}
			// $('.j-info-shop-list .j-send-shop-popup-link').magnificPopup({
			// 	items: {
			// 		src: '#send-shop-popup',
			// 		type: 'inline',
			// 	},
			// 	callbacks: {
			// 		open: function(a,b,c) {
			// 			var listItem = $(this.st.el).closest('.j-info-shop-list-item');
			// 			var id = listItem.data('id');
			// 			var form = $('form', this.content).val(id);
			// 			var infoCity = infoShops.points[ infoShops.city ].city;
			// 			var infoShop = infoShops.points[ infoShops.city ].list[ id ];
			// 			form.attr('action', '/shops/' + infoCity.uriName + '/' + infoShop.uriName + '?action=sendMail');
			// 		},
			// 	},
			// });
		}
		moveToShop();
		if( $('.j-info-shop-list-item.active',shopsWraper).length ){
			var shop_id = $('.j-info-shop-list-item.active', shopsWraper);
			shop_id = shop_id.data('id');
			var city = $('select[name=shop_city]', shopsWraper).val();
			infoShops.getListByCity( city, true ).done(function( points ){
				infoShops.updateList( points.list, shop_id );
				infoShops.putPointsForCity( city );
				infoShops.updateMetro( points.city.metro );
				infoShops.setStaticMap();
			}).done( function(){
				infoShops.openListItem( shop_id );
				infoShops.setPointUri( shop_id );
				infoShops.openPlacemark( shop_id );
				infoShops.setStaticMap( shop_id );
			});
		}

		function infoShopsShowBanners( banners, cityInfo ) {
			var sliderItem = $('.j-info-shop-map .heroSlider');
			if ( sliderItem.length ) {
				if( banners.length == 0 ) {
					banners = sliderItem.data('defaultBanners');
					if( cityInfo!==undefined ) {
						for (var i = banners.length - 1; i >= 0; i--) {
							banners[i].name = '�������� ANTA � �. ' + cityInfo.city;
						}
					}
				}
				sliderItem.slick('destroy');
				sliderItem.html( $.render[ 'shop_photo' ].render( banners ) );
				initHero();
			}
		}

		function moveToShop() {
			var shopUrl = location.pathname.split('/')[3];

			if(shopUrl){
				$('[data-uri="'+shopUrl+'"]').addClass('active');
			}
		}
	});
};
function initLogin() {
	$('#login-form').loginForm({
		inputRow: '.form-group',
		passHolder: '.form-group',
		onLoginFunc: function( msg ) {
			$('#login').modal('hide');
		}
	});
	$('#login-form2').loginForm({
		inputRow: '.form-group',
		passHolder: '.form-group',
		onLoginFunc: function( msg ) {
			window.location.href = msg.redirect;
		}
	});
	$(document).on('click', '.j-logoff', function( e ) {
		e.preventDefault();
		customer.logoff(function(){
			window.location.replace('/');
		});
	});

	$(document).on('click', '.j-show-forgotten-form', function( e ) {
		e.preventDefault();
		$('#forgotten-form2').show();
		$('#login-form2').hide();
	});
	$(document).on('click', '.j-show-login-form', function( e ) {
		e.preventDefault();
		$('#forgotten-form2').hide();
		$('#login-form2').show();
	});

	var newPassModal = $('#forgot-password, #forgotten-form2');
	$('.btn', newPassModal).on('click', function(e) {
		e.preventDefault();
		var emailEl = $('input[name=email_address]', newPassModal);
		var email_address = emailEl.val();
		if( email_address.trim().length == 0 ) {
			$('.tip', emailEl.closest('.form-group')).text('������� E-mail');
		} else {
			customer.sendNewPass(
				{
					email_address: email_address
				},
				function( msg ) {
					$('.tip', emailEl.closest('.form-group')).text( msg );
				});
		}
	});
}

function bootstrapFix() {
	// $(document).on('mouseup', function(e) {
	// 	if($(e.target).closest('.close').length) return;
	// 	if($(e.target).closest('.dropdown-menu').length){
	// 		// $(e.target).closest('.dropdown-menu').dropdown("toggle");
	// 		$(e.target).closest('.dropdown').addClass("open");
	// 	}
	// });
	$(document).on('click', function(e) {
		if( $(window).innerWidth()>1023 ) {
			if($(e.target).closest('#nav').length) return;
		}
		if($(e.target).closest('.close').length) return;
		if($(e.target).closest('.dropdown-menu').length){
			$(e.target).closest('.dropdown').addClass("open");
		}
	});

	function modalHide(){
		$('.modal.in').each(function() {
			$(this).modal('hide');
		});
	}

	$(document).on('click', '.modal-backdrop', modalHide);
	$(document).on('touchend', '.modal-backdrop', modalHide );

	$(document).on('click', '[data-open-modal]', function(e) {
		e.preventDefault();
		$('.modal.in').each(function() {
			$(this).modal('hide');
		});
		var modal = $(this).data('open-modal');
		$('body').removeClass('menu-open');
		if($(window).innerWidth() < 1024){
			$('html, body').stop().animate({
				scrollTop:0
			}, 500);
		}
		if ( $(this).data('modal-head') ) {
			$(modal).find('h2').text($(this).data('modal-head'));
		}
		if ( $(this).data('open-modal') == '#b2b-modal' ) {
			$('#b2b-modal form').show().siblings('div').remove();
		}
		setTimeout(function() {
			$(modal).modal('show');
			$('body').removeClass('menu-open');
		}, 500);
	});


	$('.tab-content a[data-toggle="tab"]').on('click', function() {
		var activeTab = $(this).attr('href');
		if($(window).innerWidth() > 1023){
			$('.cabinet-menu-list li.active').removeClass('active');
			$('.cabinet-menu-list a').each(function() {
				if($(this).attr('href') == activeTab){
					$(this).closest('li').addClass('active');
					return false;
				}
			});
		} else {
			$(this).closest('.panel').find('.panel-heading').click();
			$(this).closest('.tab-content').find('> .panel').each(function() {
				var activeTabName = activeTab.split('#')[1];
				if($(this).attr('id') == activeTabName){
					$(this).find('.panel-heading').click();
					return false;
				}
			});
		}
	});


	$('[data-toggle="tooltip"]').tooltip({html:true});
	// top menu: show hover on desktop; showclick on mobile - start
	$(window).resize(function() {
		if( $(window).innerWidth()>1023 ) {
			// $('#nav .dropdown-toggle').attr('data-toggle', '');
			$('#nav .dropdown').removeClass('open');
		} else {
			// $('#nav .dropdown-toggle').attr('data-toggle', 'dropdown');
		}
	});
	// top menu: show hover on desktop; showclick on mobile - end
	$(window).resize();
};

function initProduct() {
	var zoomWidth,
		zoomHeight;
	function calcZoom() {
		zoomWidth = $('.product-section .product-description-container').position().left + $('.product-section .product-description-container').innerWidth() - $('.product-section .product-preview').innerWidth();
		zoomHeight = $('.product-preview-slider .slick-current img').innerHeight();
		zoomConfig.zoomWindowWidth = zoomWidth;
		zoomConfig.zoomWindowHeight = zoomHeight;
	};
	var zoomConfig = {
		lensFadeIn:500,
		lensFadeOut:500,
		zoomWindowFadeIn:500,
		zoomWindowFadeOut:500,
		borderSize:0,
		zoomWindowWidth:zoomWidth,
		zoomWindowHeight:zoomHeight,
		lensColour:'rgba(255,255,255,.4)',
		lensBorderColour:'#fff'
	}

	$('a.grouped_elements').on('click', function(e){
		e.preventDefault();
	});

	$('.product-section .product-preview-slider').on('init setPosition', function() {
		if($(window).innerWidth() > 1023){
			calcZoom();
			$(this).find('.slick-current img').elevateZoom(zoomConfig);
		} else {
			var image = $(this).find('.slick-current img');
			$.removeData(image, 'elevateZoom');
			$('.zoomContainer').remove();
		}
	});
	$('.product-section .product-preview-slider').slick({
		arrows:false,
		dots:true,
		responsive:[
			{
				// breakpoint:768,
				// settings:{
				// 	infinite:false
				// }
			}
		]
	});
	$('.product-section .product-preview-slider').on('beforeChange', function() {
		var image = $(this).find('.slick-current img');
		$.removeData(image, 'elevateZoom');
		$('.zoomContainer').remove();
	});
	$('.product-section .product-preview-slider').on('afterChange', function() {
		if($(window).innerWidth() > 1023){
			calcZoom();
			$(this).find('.slick-current img').elevateZoom(zoomConfig);
		}
	});

	// ���������� ������ � �������
	$('.order-panel .buy-btn').on('mouseup click', function(e) {
		if( $(this).closest('.product-description-container').find('.size-picker input:radio').length && $(this).closest('.product-description-container').find('.size-picker input:checked').length == 0 ){
			e.preventDefault();
			$(this).closest('.product-description-container').find('.size-picker').addClass('error');
		} else {
			if($(this).closest('.modal').length){
				$('#product-preview').modal('hide');
			}
			// if($(window).innerWidth() > 1023 || window.PROSKTR.content == "product_info"){
			// 	e.preventDefault();
			// 	$('html, body').stop().animate({
			// 		scrollTop:0
			// 	}, 350, function(){
			// 		$('.header-basket-holder')
			// 			.addClass('open')
			// 			.find('.header-basket')
			// 			.addClass('new-item-drop');
			// 	});
			// 	if ( $(window).innerWidth() < 1024 ) {
			// 		var top = $('#header').offset().top + $('#header').outerHeight();
			// 		$('.navbar-nav>li>.dropdown-menu').css('top', top + 'px');
			// 	}
			// }
		}
	});

	$(document).on('click', '.header-basket-holder.open .close', function(){
		$(this).closest('.header-basket-holder').removeClass('open').find('.header-basket').removeClass('new-item-drop');
	});

	$('.size-picker input').on('change', function() {
		$(this).closest('.size-picker').removeClass('error');
		var productsId = $(this).closest('form').data('productsId');
		$('.j-add-product[data-products-id=' + productsId + ']')
			.removeClass('hidden')
			.parent()
			.find('.go-shopping-cart')
			.addClass('hidden');
	});
	//quick view of the product start
	if(viewport().width < 1024) {
		$(document).on('click','.j-fast-view-tablet-and-mobile',function(event){
			event.preventDefault();
			var modal = $('#product-preview-tablet-and-mobile');
			$('.data-area', $(modal)).html(getPreloaderBody());
			$(modal).addClass('active');
			stopBodyScroll(true);
			$.ajax({
				url: this.href,
				data: {action: 'fast_view', 'ajax': 3, 'fromTablet':1},
				dataType: 'json',
				context: this,
				success: function (msg) {
					$('.data-area', $(modal)).html(msg.html);
				}
			});
		});
	}else{
		$('#product-preview').on('show.bs.modal', function (e) {
			var button = $(e.relatedTarget);
			var productInfo = $(button).closest('.js-fast-view').data('info');
			$.ajax({
				url: button.data('href'),
				data: {action: 'fast_view', 'ajax': 3},
				dataType: 'json',
				context: this,
				success: function (msg) {
					$('.modal-product-item', this).html(msg.html);
					if ($(this).data('db-shown') == 1) {
						initFastViewProduct();
						$(this).data('db-shown', 0);
						$(this).data('content-loaded', 0);
					} else {
						$(this).data('content-loaded', 1);
					}
					var _that = this;
					setTimeout(function () {
						var elem = $(_that).find('.product-description-container');
						if (elem.data('start-position') == undefined) {
							elem.data('start-position', elem.position().top);
						}
					}, 500);
					$('#product-preview .product-preview').addClass('loading');
					$('#product-preview .product-preview .product-preview-main').imagesLoaded(function () {
						$('#product-preview .product-preview').removeClass('loading');
					});
				}
			});
		});
	}
	//quick view of the product end

	$('#product-preview').on('shown.bs.modal', function() {
		if( $(this).data('content-loaded') == 1 ) {
			initFastViewProduct();
			$(this).data('content-loaded', 0);
			$(this).data('db-shown', 0);
		} else {
			$(this).data('db-shown', 1);
		}
	});
	$('#productPopupZoom').on('hide.bs.modal', function(e) {
		$('#productPopupZoom').css('opacity','');
	});
	$('#productPopupZoom').on('shown.bs.modal', function(e) {
		var button = $(e.relatedTarget);
		var id = $(button).data('name');
		$('#productPopupZoom').imagesLoaded(function(){
			$('#productPopupZoom').animate({ scrollTop: $('#'+id).offset().top - $('#productPopupZoom').offset().top }, 0).css('opacity',1);
		});
	});
	// $('#product-preview').modal('toggle');

	// order one click - start
	var oneClickWrap = $('#quick-view');
	oneClickWrap.on('show.bs.modal', function(e) {
		var button = $(e.relatedTarget);
		var order_type = '';
		if( $(button).hasClass('booking') ) {
			order_type = 'booking';
			$('.modal-header h2', this).text( '�������� ������� ����� � ������� � ��������' );
		} else {
			order_type = 'fast_order';
			$('.modal-header h2', this).text( '�������� ������� �����' );
		}
		var info_shop_id = $(button).data('info_shop_id');
		var products_id = $(button).data('products-id');
		$('#product-in-shops.modal.in').each(function() {
			$(this).modal('hide');
		});
		$.ajax({
			url: '/order_one_click.php?action=getForm',
			data: {
				products_id: products_id,
				order_type: order_type,
				info_shop_id: info_shop_id
			},
			type: 'post',
			dataType: 'json',
			context: this,
			success: function(msg) {
				$('.modal-body', this).html( msg.orderOneClick );
				initForm( this );
			}
		});
	});

	oneClickWrap.on('shown.bs.modal', function(e) {
		if ( window.outerWidth > 1024 ) {
			$('.quick-offer-form input[name=firstname]', this).focus();
		}
	});

	oneClickWrap.on('click', '.pass-forgotten', function(e) {
		e.preventDefault();
		el = this;
		form = $(el).closest('form');
		customer.sendNewPass({
				email_address: form.find('input[name=email_address],input[name=email_or_phone]').val()
			},
			function( msg ) {
				$(el).closest('.submit-row').find('.hint').html(msg);
			}
		);
	});
	oneClickWrap.on('click', '.pass-btn', function(e) {
		e.preventDefault();
		var el = $(this);
		var form = el.closest('form');
		var customerInfo = {};
		customerInfo.pass = $('input[name=password]', form).val();
		customerInfo.email = $('input[name=email_or_phone]', form).val();
		customerInfo.options = {email_or_phone: true};
		customer.login({
			customerInfo: customerInfo,
			error: function( msg ) {
				form.find('.hint').addClass('error').html(msg.err_mes);
			}
		}, function( msg ) {
			$('#quick-view .nav-tabs>li:first-child a').trigger('click');
			var orderForm = $('#fast-order-form');
			$('input[name=firstname]', orderForm).val( msg.name + " " + msg.lastname );
			$('input[name=phone]', orderForm).val( msg.phone );
			if( msg.asLastOrder=='1' ) {
				$('input[name=same_order]', orderForm).val( 1 );
			}
			orderForm.submit();
		});
	});
	oneClickWrap.on('change', 'input[name^=prod_id]', function(){
		$('input[name=option_id]', oneClickWrap).val('1');
		$('input[name=option_value]', oneClickWrap).val( $(this).val() );
		if( $(this).closest('.drop-size').length ) {
			$(this).closest('.drop-size').hide();
			$('.drop-size-bg', oneClickWrap).hide();
			$('#fast-order-form').submit();
		} else {
			if ( $('.drop-size').css('display') == 'block' ) {
				$('.drop-size').hide();
				$('.drop-size-bg').hide();
				$("#fast-order-form").submit();
			}
		}
	});
	oneClickWrap.on('submit', '#fast-order-form', function(e) {
		if ( $('input[name=option_value]', oneClickWrap).val() == '' ) {
			$('.drop-size-bg', oneClickWrap).show();
			$('.drop-size', oneClickWrap).show();
		} else {
			if( !e.isDefaultPrevented() ) {
				$('input:submit', this).hide();
				$('.order-success-btn', this).removeClass('hidden');
				if(window.ga) ga('send', 'event', 'forms', 'submit', 'oneclick');
				if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('oneclick');
			}
		}
	});
	// order one click - end

	if( PROSKTR.content == "product_info" && $('#add_product-form').length ) {
		cookieObj.addViewedProduct( $('#add_product-form').data('productsId').toString() );
	}

	// ��������� ��������� ����� � ������
	$(document).on('change', '.j-select-size-type', function(){
		var form = $(this).closest('form');
		var activeNew = $('.size-type-list .size-type-' + $(this).val(), form);
		activeNew.addClass('active').siblings().removeClass('active');
		cookieObj.write('proskater_cookie', 'size-type-'+$(this).data('sizetype'), $(this).val());
	});

	$(document).on('click', '#add_product-form .size-picker-list span', function(e) {
		var input = $(this).closest('label').find('input');
		$('.same-products-links .same-products').addClass('hidden'); // �������� ������ �������� ������ ������ ������-�� �������
		var productWrap = $(this).closest('.modal-product-item, .product-section');
		$('.availability', productWrap).addClass('hidden');
		if( input.prop('disabled') ) {
			$('.have-only-one-product').addClass('hidden');
			$('.availability.notin', productWrap).removeClass('hidden');
			$('.j-add-product').addClass('disabled');
			$('#add_product-form input:radio:checked').prop('checked', false);
			var link = $('.same-products-links [data-option=' + input.val() + ']');
			if( link.length ) {
				link.removeClass('hidden');
				$('a', link).text( link.data('text').replace('[options_value]', $(this).text()) );
			}
		} else {
			$('.j-add-product').removeClass('disabled');
			$('.availability.in', productWrap).removeClass('hidden');
			if(1 === +$(this).data('stock-balances')){
				$('.have-only-one-product').removeClass('hidden');
			}else{
				$('.have-only-one-product').addClass('hidden');
			}
			var deliveryDescrForm = $('#products_buttom_info_delivery');
			if( deliveryDescrForm.length ) {
				$('input[name=for_product]', deliveryDescrForm).val( $('#add_product-form').data('productsId') + '{1}' + input.val() );
				deliveryDescrForm.submit();
				$('.product-delivery-choose-size').addClass('loading');
			}
		}
	});

	$(document).on('click', '.drop-size-bg', function(e) {
		$('.drop-size-bg').hide();
		$('.drop-size').hide();
	});

	$(document).on('click', '.product-section .drop-size input, #product-preview .drop-size input', function(e) {
		var input = $(this);
		var form = $('#add_product-form');
		var rowForDrop = $(this).closest('.row-for-drop');
		$('.drop-size', rowForDrop).hide();
		$('.drop-size-bg', rowForDrop).hide();
		var addBtn = $('.j-add-product', rowForDrop);
		// var productsId = addBtn.data('productsId');
		$('label:visible input:radio[value=\"' + input.val() + '\"]', form).prop('checked', true).trigger('change');
		addBtn.trigger('click');
	});

	// �������� � �������
	$(document).on('click', '.j-add-product', function(e) {
		e.preventDefault();

		var form = $('form[name=add_product][data-products-id=' + $(this).data('productsId') + ']');

		if( $('input[name="id[1]"]:radio', form).length && $('input[name="id[1]"]:checked', form).length == 0 ) {
			var rowForDrop = $(this).closest('.row-for-drop');

			if( rowForDrop.length ) {
				$('.drop-size', rowForDrop).show();
				$('.drop-size-bg', rowForDrop).show();
			}
		} else {
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({'event': 'webit_click_addToCart'});
			var productInfo = form.serializeArray();

			shoppingCart.addProducts({
				product_info: form.serializeArray()
			}, {
				checkDublicate: false
			});

			var value = '';

			if ( $('input[name="id[1]"]:checked', form).length ) {
				value = $('input[name="id[1]"]:checked', form).val();
			} else {
				value = $('input[name="id[1]"]', form).val();
			}

			$('.j-show-add-product-modal').data( 'value', value );
			$(this).addClass('hidden')
				.parent()
				.find('.go-shopping-cart')
				.removeClass('hidden');
		}
	});

	$('.header-basket-holder').on('click', '.j-cart-delete', function(e) {
		e.preventDefault();
		shoppingCart.deleteProduct({
			products_id: $(this).closest('li').data('productsId')
		});
	});

	$(document).on('click', '.j-wishlist-add', function(e) {
		e.preventDefault();
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({'event': 'webit_click_favourites'});
		var el = this;
		if( $(this).data('wishlist') ) {
			wishlist.remove( $(this).data('products_id'), function() {
				$(el).data('wishlist', 0);
				$(el).removeClass('active');
			} );
		} else {
			wishlist.add( $(this).data('products_id'), function() {
				$(el).data('wishlist', 1);
				$(el).addClass('active');
			} );
		}
	});

	var deliveryDescrForm = $('#products_buttom_info_delivery');
	if( deliveryDescrForm.data('onload') == 1 ) {
		deliveryDescrForm.submit();
	}
	$('#change-city-popup form').on('submit', function(e) {
		e.preventDefault();
		var city = $('.city-tab.active [name=city]', this).val()
		var zone_id = $('.city-tab.active [name=zone_id]', this).val()
		changeCity.change(city, zone_id);
	});
	changeCity.subscribe(function(city, zone_id, response) {
		$('.js-set-city-text').text(response.city);
		$('#products_buttom_info_delivery .js-set-city-val').val(response.city);
		$('#products_buttom_info_delivery .js-set-zone_id-val').val(response.zone_id);
		$('#products_buttom_info_delivery').trigger('submit');
	});
	changeCity.subscribe(function(city, zone_id, response) {
		$('#change-city-popup').modal('hide')
		$('#cities-popup-switcher').prop('checked',false)
	});

	if ( $('html').hasClass('touch') && $("a.grouped_elements") ) {
			$("a.grouped_elements").fancybox({
				modal: false,
				tpl: {
					next: '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;">&gt;</i></a>',
					prev: '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;">&lt;</a>',
					closeBtn: '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">&times;</a>'
				},
				afterShow: function () {
					$('.fancybox-image').wrap(
						$('<div />')
					);
					if ($('html').hasClass('touch')) {
						var startPosY = ($('.fancybox-inner').find('img').height() - $('.fancybox-inner').height()) / 2;
						var startPosX = ($('.fancybox-inner').find('img').width() - $('.fancybox-inner').width()) / 2;
						$('.fancybox-inner').find('img').css('margin-top', (-startPosY) + 'px');
						$('.fancybox-inner').find('img').css('margin-left', (-startPosX) + 'px');
						$('.fancybox-inner').get(0).addEventListener('touchstart', function (e) {
							var touches = e.changedTouches;
							var sy = touches[0].pageY,
								sx = touches[0].pageX,
								img = $(this).find('img'),
								limitx = $(img).width() - $(this).width(),
								limity = $(img).height() - $(this).height(),
								left = parseInt($(this).find('img').css('margin-left'), 10),
								top = parseInt($(this).find('img').css('margin-top'), 10);
							listener = function (e) {
								touches = e.changedTouches;
								var y = touches[0].pageY,
									x = touches[0].pageX,
									marginLeft = (x - sx + (left)),
									marginTop = (y - sy + (top));
								if (marginLeft < -limitx) {
									img.css('margin-left', -limitx);
								} else if (marginLeft > 0) {
									img.css('margin-left', 0);
								} else {
									img.css('margin-left', marginLeft);
								}

								if (marginTop < -limity) {
									img.css('margin-top', -limity);
								} else if (marginTop > 0) {
									img.css('margin-top', 0);
								} else {
									img.css('margin-top', marginTop);
								}
								//img.css('margin-top',-(ry*y));
								e.preventDefault();
							}
							this.addEventListener('touchmove', listener, false);
							this.addEventListener('touchend', function h() {
								this.removeEventListener('touchmove', listener, false);
								this.removeEventListener('touchend', h, false);
							});
							//e.preventDefault();
						}, false);
					}
				},
			});
	}
};

function initProductList() {
	$('.product-list-slider-index, .js-product-list-slider-index').slick({
		slidesToShow:4,
		infinite:true,
		responsive:[
			{
				breakpoint:1024,
				settings:{
					slidesToScroll: 2,
					arrows:true
				}
			},
			{
				breakpoint:800,
				settings:{
					arrows:true,
					slidesToScroll: 4,
					slidesToShow:4
				}
			},
			{
				breakpoint:768,
				settings:{
					arrows:true,
					slidesToScroll: 4,
					slidesToShow:4
				}
			},
			{
				breakpoint:680,
				settings:{
					arrows:true,
					slidesToScroll: 4,
					slidesToShow:4
				}
			},
			{
				breakpoint:560,
				settings:{
					arrows:false,
					dots: true,
					slidesToScroll: 2,
					slidesToShow:2
				}
			},
		]
	});

	$('.recently-viewed-list').slick({
		slidesToShow:7,
		infinite:false,
		responsive:[
			{
				breakpoint:1367,
				settings:{
					slidesToShow:6
				}
			},
			{
				breakpoint:1024,
				settings:{
					arrows:false,
					slidesToShow:1,
					variableWidth:true
				}
			}
		]
	});

	$('.recently-viewed-list').each(function() {
		if($(this).find('.recently-viewed_item').length > $(this).slick('slickGetOption', 'slidesToShow')){
			$(this).addClass('slider-active');
		}
		$(this).on('setPosition', function() {
			if($(this).find('.recently-viewed_item').length > $(this).slick('slickGetOption', 'slidesToShow')){
				$(this).addClass('slider-active');
			} else {
				$(this).removeClass('slider-active');
			}
		});
	});

	$('.section-expanded-heading').on('click', function() {
		$(this).toggleClass('closed').siblings('.section-expanded').stop().slideToggle();
	});
	$(window).one('scroll', function () {
		var productsWrap = $('.catalog-section .product-list.dynamic');
		$('img.js-catolog-lazy', productsWrap).each(function() {
			$(this).removeClass('js-catolog-lazy').attr('src', $(this).data('original'));
		});
	});
	if( $('.js-product-spritespin').length ) {
		$(document).on('click', '.spritespin-holder', '.product-preview', function(){
			$(this).hide();
			var me = this,
					el = $(this).closest('.js-product-spritespin'),
					spin = $('.spritespin-visual', el),
					data = spin.data('spritespin-options'),
					isMobile = spin.data('mobile') == '1',
					frames = SpriteSpin.sourceArray( data.prefix + '{frame}' + data.postfix , {
				frame: [1, data.count],
				digits: 1,
			});
			var slide = $('.spin-slider', el);
			spin.spritespin({
				source: frames,
				width: isMobile ? $(window).width() : data.width,
				sense: 3,
				height: isMobile ? $(window).width() * data.height / data.width : data.height,
				// animate: false,
				frameTime: 80,
				plugins: [
					'move',
					'360'
				],
				onInit: function(){
					slide.slider({
						min: 0,
						max: frames.length - 1,
						value: 0,
						slide: function(e, ui){
							var api = spin.spritespin('api');
							api.stopAnimation();
							api.updateFrame(ui.value);
						}
					})
				},
				onFrame: function(e, data){
					slide.slider('value', data.frame);
				},
			});
			el.on('mouseleave', function() {
				api = spin.spritespin("api");
				api.startAnimation();
			});
			el.on('click', function() {
				spin.spritespin("destroy");
				$(me).show();
				$(this).off();
			});
		});
	}
};

$(document).on('click', '.catalog-menu-list .dropdown-toggle', function(e){
	e.preventDefault();
	$(this).closest('li').toggleClass('open').siblings('li').removeClass('open');
});

$(document).on('click', '.j-fast-view-btn', function(e){
	e.preventDefault();
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({'event': 'webit_click_fastView'});
});

$(document).on('click', '.active-filters-block li', function(){
	if ( $(this).hasClass('clearAll') ) {
		window.listing.clearForm();
		return;
	}
	var filter = $(this).data('filter');
	var group = $(this).data('group');
	var value = $(this).data('value');
	var text = $(this).data('text');
	if ( text == 'fpricefrom' ) {
		$( "#slider-range" ).slider('values', [$( "#slider-range" ).slider('option', 'min'), $('#amount1').val()]);
		window.listing.info.prices = [0, $('#amount1').val() == $( "#slider-range" ).slider('option', 'max') ? 0 : $('#amount1').val() ];
		$('#filtr-form').submit();
	} else if ( text == 'fpriceto' ) {
		$( "#slider-range" ).slider('values', [$('#amount').val(), $( "#slider-range" ).slider('option', 'max')]);
		window.listing.info.prices = [$('#amount').val() == $( "#slider-range" ).slider('option', 'min') ? 0 : $('#amount').val(), 0];
		$('#filtr-form').submit();
	} else {
		$('.filter-container input[data-for=' + filter + ']').each(function(){
			if ( $(this).data('group') == group && $(this).val() == value ) {
				$(this).trigger('click');
			}
		});
	}
});

$(document).on('click', '.scrollToReviews', function(e){
	e.preventDefault();
	$('html, body').animate({ scrollTop: $('#reviewsSection').offset().top });
});

const loadScript = function (url) {
    return new Promise(function (resolve, reject) {
        const script = document.createElement('script');
        script.src = url;
        script.addEventListener('load', function () {
            resolve(true);
        });
        document.head.appendChild(script);
    });
};

$(function(){
	var alreadyClickedInStockButton = false;

	$(document).on('click', '.js-popup-product-shop', function(e){
		if(!alreadyClickedInStockButton){
			alreadyClickedInStockButton=true;
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({'event': 'webit_click_inStock'});
		}
		if($('[data-id=ymaps]').length>0){
			loadScript($('[data-id=ymaps]').attr('data-src')).then(function () {
				$('[data-id=ymaps]').remove();
			    $('#product-in-shops').modal('show');
			    $('#product-in-shops .js-product-shop-item').each(function(){
			        if($('.j-info-shop-map-wraper',$(this)).length) {
					    initInfoShops($(this) );
				    }
			    });
			});
		}
		else{
			$('#product-in-shops').modal('show');
			$('#product-in-shops .js-product-shop-item').each(function(){
				if($('.j-info-shop-map-wraper',$(this)).length) {
					initInfoShops($(this));
				}
			});
		}

	});
});

$(document).on('click', '#call_me_message-popup', function(){
	if($(this).hasClass('btn')){
		var phone = ($(this).data('new_phone') == undefined ? '' : '7' + $(this).data('new_phone').replace(/[^0-9]/g, '').substr(1) );
		$.ajax({
			url:'/account_history_info.php?action=callme&no_redirect=1&order_id='+$(this).data('ordersId')+'&new_phone='+phone,
			context: this,
			success: function(){
				$(this).html('<b>��� ������ �� ������ ������. �� ���������� � ������� 30 ���.</b>').removeClass('btn').addClass('mess');
				$(this).closest('.modal-body').find('#change_number').css('display', 'none');
			}
		});
	}
});

$(document).on('click', '#change_number', function(e){
	$(this).hide();
	$('.change_number_div').css('display', 'flex');
});

$(document).on('click', '.change_number', function(e){
	var popup = $(this).closest('.modal-body');
	var callbackbtn = popup.find('#call_me_message-popup');
	var new_phone = popup.find('input[name=phone]').val();
	callbackbtn.data('new_phone',new_phone);
	callbackbtn.html('��������� ��� �� ' + new_phone);
	popup.find('.change_number_div').css('display', 'none');
	$('#change_number').show();
});

$(document).ready(function () {
	initNewsHeroSlider();
	initMainPageBottomSlider();
	try {
		ymaps.ready(initYandexMaps);
	}catch (e){

	}
	if($('.js-login')) {
		$(document).on('click', '.js-login', function () {
			$('#login').modal('show');
			return false;
		});
	}

	$('.multifilter-item-new input[data-bgcolor]').each(function(){
		setCheckboxColor.apply(this);
		$(this).change(setCheckboxColor);
	});

	/**
	 * ������������� ���� �������� ��������� ��� data-bgcolor ��� ������������ ��� �������� span
	 * @param input
	 */
	function setCheckboxColor(){
		var span = $('.js-color-span',$(this).parent()),
			checked = $(this).prop('checked'),
			color = $(this).data('bgcolor');
		if(span && color !== '#FFFFFF') {
			span[0].style.background= checked ? $(this).data('bgcolor')+'' : 'white';
		}
	}
})

function initSidebar() {
	$('#sidebar h3').on('click', function() {
		if($(window).innerWidth() < 1024){
			$(this).closest('#sidebar').toggleClass('open');
		}
	});
	$(document).on('click', function(e) {
		if($(e.target).closest('#sidebar').length) return;
		$('#sidebar').removeClass('open');
	});
};

function initLimitOffer() {
	$('#registr-coupon-form').submit(function(e) {
		if( !e.isDefaultPrevented() ) {
			e.preventDefault();
			$('.error', this).html('');
			$.ajax({
				type: "POST",
				url: '/limit_offer.php',
				data : $(this).serializeArray(),
				dataType : "json",
				context: this,
				success: function( msg ){
					if( msg.res == true ) {
						$('.info.hidden', this).removeClass('hidden');
						$('input[type=submit]', this).prop('disabled', true);
						$('input[type=submit]', this).css('opacity', '.6');
					} else {
						var text = "";
						if( msg.error_msg == 'empty_email' ) {
							text = '��������� ���� Email.'
						} else if ( msg.error_msg == 'used_mail' ) {
							text = '���� email ��� ��������������� � �����.'
						}
						$('.error', this).html( text );
					}
				}
			});
		}
	});

	$('#check-coupon-form').submit(function(e){
		if( !e.isDefaultPrevented() ) {
			e.preventDefault();
			var form = $(this);
			$('.info div').html('');
			form.find('input[type=button]').addClass('hidden');
			$.ajax({
				type: "POST",
				url: '/limit_offer.php',
				data : $(this).serializeArray(),
				dataType : "json",
				success: function( msg ){
					if( msg.res == true ) {
						form.find('.info div').html( '����� �� �����������' );
						form.find('input[type=button]').removeClass('hidden');
					} else {
						form.find('.info div').html( '����� �� ������ ��� ��� �����������.' );
					}
				}
			});
		}
	});

	$('#check-coupon-form input[type=button]').click(function(e){
		e.preventDefault();
		var form = $(this).closest('form');
		$('.info div').html('');
		var data = form.serializeArray();
		data[2]['value'] = 'activate';

		$.ajax({
			type: "POST",
			url: '/limit_offer.php',
			data : data,
			dataType : "json",
			success: function( msg ){
				if( msg.res == true ) {
					form.find('.info div').html( '����� �����������' );
					form.find('input[type=button]').addClass('hidden');
				}
			}
		});
	})
}

$( window ).on('mousemove', checkShowExit);

$( document ).ready(function(){

	//����������� ������� �������� �������
	var copyopen = cookieObj.getCookie('copyopen');
	if( copyopen === undefined ) copyopen = 0;
	copyopen++;
	cookieObj.setCookie('copyopen', copyopen );

});

$( window ).on('unload', function(){
	//��������� ������� �������� �������

	var copyopen = cookieObj.getCookie('copyopen');
	if( copyopen === undefined ) copyopen = 0;
	copyopen--;
	cookieObj.setCookie('copyopen', copyopen );

});

function checkShowExit(e){
	var positionY = e.pageY - $(window).scrollTop();
	if(positionY <= 5){
		showExitPopup();

	}
}

function showExitPopup(){
	var src = false;

	if ( $(window).outerWidth() < 1024 ) return;
	if( cookieObj.getCookie('copyopen') != 1 ) return;
	if( cookieObj.getCookie('customerid') != 0 && cookieObj.getCookie('customerid') !== undefined) return;
	if( PROSKTR.customer.id != 0 ) return;
	if( cookieObj.getCookie('customerorderCnt') == 0 || cookieObj.getCookie('customerorderCnt') === undefined ){
		if( cookieObj.getCookie('exitpopup_viewed') == 1 ) return;
		if( cookieObj.getCookie('product_id_viewed_cur') === undefined ) return;
		if( cookieObj.getCookie('product_id_viewed_cur').length === 0  ) return;
		if( cookieObj.getCookie('product_id_viewed_cur').split(',').length < 2  ) return;
		src = '#exit-popup-viewed';
		if(window.ga) ga('send', 'event', 'Popup', 'Popup_ViewedProducts', 'ViewedProducts_Show', {'nonInteraction': 1});
		cookieObj.setCookie('exitpopup_viewed', 1);
	} else {
		if( cookieObj.getCookie('exitpopup_basket') == 1 ) return;
		if(window.ga) ga('send', 'event', 'Popup', 'Popup_ShoppingCartProducts', 'ShoppingCartProducts_Show', {'nonInteraction': 1});
		src = '#exit-popup';
		cookieObj.setCookie('exitpopup_basket', 1);
	}
	//$( src ).modal('show');
	$( window ).off('mousemove', checkShowExit);
}

$('.more-reviews a').on('click', function(e){
	e.preventDefault();

	var ctrl = $(this);
	if( ctrl.data('loading') == 1 ) {
		return;
	}
	var cont = ctrl.closest('.section-expanded');
	var list = cont.find('.reviews-list');
	var revBlock = cont.closest('.reviews-block');
	// cont.addClass('open');
	// ctrl.find('span').text('������');
	ctrl.data('loading', 1);
	product.getMoreReviews( {
		products_id: $('input[name=products_id]').val(),
		limit:       ctrl.data('limit'),
		offset:      ctrl.data('from'),
		type:        ctrl.data('type'),
	}, function( msg ) {
		var el = list.append( msg.data );
		initRate();
		ctrl.data('from', ctrl.data('from') + ctrl.data('limit'));
		if( ctrl.data('from') >= msg.reviewsCount ) {
			ctrl.hide();
		}
		cont.find('.expand').slideDown({
			complete: function() {
				ctrl.data('loading', 0);
				list.find('>li').appendTo($('>.reviews-list', revBlock));
				cont.find('.more-reviews').hide();
			}
		});
	} );
});
$('.show-more').click(function( e ) {
	e.preventDefault();
	var form = $('#reviews-list-form');
	if( parseInt(form.attr('loading')) == 1 ) {
		return false;
	}
	form.attr('loading', 1);
	var page_el = form.find('input[name=page]');
	page_el.val( parseInt(page_el.val()) + 1 );
	formChangeFunc( form, true, true );
	// if ( parseInt( $(this).data('pages') ) >= parseInt( page_el.val() ) ) {
	// 	$(this).hide();
	// }
});
$('#reviews-list-form').change( function( e ) {
	var form = $(this);
	if( parseInt(form.attr('loading')) == 1 ) {
		return false;
	}
	form.attr('loading', 1);
	form.find('input[name=page]').val(1);
	formChangeFunc( form, false, true );
} );

var formChangeFunc = function( form, appendFlag, historyFlag ) {
	var formData = form.serializeArray();
	if(historyFlag && history.pushState) {
		var queryString = $.param(formData);
		history.pushState(formData, $("title").text(), form.attr('action')+'?'+queryString.replace('&ajax=3',''));
	}
	$.ajax({
		url: form.attr('action'),
		data: formData,
		dataType: 'json',
		success: function( msg ) {
			if( appendFlag ) {
				$('.reviews-list').append( msg.data.content );
			}else {
				$('.reviews-list').html( msg.data.content );
				$(window).scrollTop(0);
			}
			form.attr('loading', 0);
			$('.reviews-block .count-model').text(msg.pagination.itemCountText);
			$('.pagination-page-list.pagination-link').html(msg.pagination.html);
			if( parseInt( msg.pagination.pageNumber ) >= parseInt( msg.pagination.pageCount ) ) {
				$('.show-more').hide();
			}
			initRate();
		},
	});
}
$('#call-me-form').validate({
	valid: function( event, options ) {
		event.preventDefault();
		$('#call-me-form .hidden-submit').click();
		var phone = $('#call-me-form').find('input[name=phone]').val();
		var url;
		$.ajax({
			url: "/ajax.php?type=oktell",
			data : {
					phone : '7' + phone.replace(/[^0-9]/g, '').substr(1),
					},
		});
	}
});

$('#exit-popup, #exit-popup-viewed').on('show.bs.modal', function(e) {
	var _this = this;
	var type = 'reload_exitPopup_viewed';
	if ( $(this).attr('id') == 'exit-popup' ) {
		type = 'reload_exitPopupBottom';
	}
	$.ajax({
		url: '/ajax.php?type=' + type,
		dataType: 'json',
		success: function( rtn ) {
			$('.j-product-gallery').html( rtn['html'] );
			setTimeout(function() {
				var qty = $('.js-products-slider-exit > li', _this).length > 1 ? 2 : 1;
				$('.js-products-slider-exit', _this).slick({
					dots: false,
					arrows: false,
					slidesToShow: qty,
					slidesToScroll: qty,
					responsive: [
							{
								breakpoint: 767,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
								},
							}
						]
				});
			}, 500);
		},
	});
});

function setFixFilters( scrollValueY ) {
	var fixedHeader = new FixedHeader()
	var windowWidth = window.innerWidth || $(window).width();
	var filtersEl = $('#filtr-form');
	var container = $('#filtr-form').closest('.catalog-section');
	if( windowWidth <= 767 || container.height() <= filtersEl.height() + 52 ) {
		filtersEl.css({
			'position': 'static',
			'width': '',
		});
		return false;
	}
	// scrollValueY
	var windowHeight = $(window).height();
	var filtersWrap = $('.filter-sidebar');
	if( filtersWrap.length == 0 ) {
		return false;
	}
	var filtersHeight = filtersEl.height();
	var filtersElTop = filtersEl.offset().top;
	var filtersElBottom = filtersElTop + filtersHeight;
	var topMin = filtersWrap.offset().top; // ����������� ���������� ������
	var topMax = topMin + Math.max(filtersWrap.height(), filtersHeight); // ����������� ���������� �����

	var filtersPadding = 20;
	var addTop = 0;
	if( fixedHeader.flags && fixedHeader.flags.fixedHeader === true ) {
		addTop = fixedHeader.elements.headerMiddle.outerHeight();
		windowHeight-= addTop;
		// topMin+= addTop;
	}

	if( filtersHeight <= windowHeight ) { // ���� ������� ������� � �����
		if( scrollValueY >= 0 ) { // ���� �������� ����
			if( filtersElBottom >= topMax ) { // ��������� � ����� ���
				filtersEl.css({
					'position': 'absolute',
					'top': (topMax - filtersHeight - topMin) + 'px',
					'bottom': '',
				});
			} else if( latestKnownScrollY + filtersHeight >= topMax ) { // ���� �������, ���� ������������� �������
				filtersEl.css({
					'position': 'absolute',
					'top': (topMax - filtersHeight - topMin) + 'px',
					'bottom': '',
				});
			} else if( latestKnownScrollY > filtersElTop ) { // TODO ���������, ��� �� ��� fixed
				filtersEl.css({
					'position': 'fixed',
					'top': 0 + 'px',
					'bottom': '',
				});
			}

		} else { // ���� �������� �����
			if( filtersElTop <= topMin ) { // ��������� � ����� ����. TODO ���������, ��� �� ��� fixed
				filtersEl.css({
					'position': 'static',
					// 'top': 0,
				});
			} else if( latestKnownScrollY - addTop <= filtersElTop ) {
				filtersEl.css({
					'position': 'fixed',
					'top': addTop + 'px',
					'bottom': '',
				});
			}
		}
	} else { // ���� ������� �� ������� � �����
		if( scrollValueY >= 0 ) { // ���� �������� ����
			if( filtersElBottom >= topMax ) { // ��������� � ����� ���
				filtersEl.css({
					'position': 'absolute',
					'top': (topMax - filtersHeight - topMin) + 'px',
					'bottom': '',
				});
			} else if( filtersElBottom > windowHeight + latestKnownScrollY ) { // ���� ��� �� ����� ���� ��������
				filtersEl.css({
					'position': 'absolute',
					'top': ( filtersElTop - topMin ) + 'px',
					'bottom': '',
				});
			} else if( latestKnownScrollY + filtersHeight >= topMax && windowHeight + latestKnownScrollY >= topMax ) { // ���� �������, ���� ������������� �������
				filtersEl.css({
					'position': 'absolute',
					'top': (topMax - filtersHeight - topMin) + 'px',
					'bottom': '',
				});
			} else {
				filtersEl.css({
					'position': 'fixed',
					'top': '',
					'bottom': '0px',
				});
			}
		} else { // ���� �������� �����
			if( filtersElTop <= topMin ) { // ��������� � ����� ����
				filtersEl.css({
					'position': 'static',
					// 'top': 0,
				});
			} else if( filtersElTop < latestKnownScrollY + addTop ) { // ���� ��� �� ����� ����� ��������
				filtersEl.css({
					'position': 'absolute',
					'top': ( filtersElTop - topMin ) + 'px',
					'bottom': '',
				});
			} else {
				filtersEl.css({
					'position': 'fixed',
					'top': addTop + 'px',
					'bottom': '',
				});
			}
		}
	}

}

$(window).resize(function() {
	setFiltersSize();
});
setFiltersSize();
function setFiltersSize() {
	var windowWidth = window.innerWidth || $(window).width();
	var filtersWrap = $('.filter-sidebar');
	var filtersEl = $('#filtr-form');
	if( filtersWrap.length == 0 ) {
		return false;
	}
	if( windowWidth <= 767 ) {
		filtersEl.css({
			'position': 'static',
			'width': '',
		});
	}
}

$('#dateStorageProlongation').on('click', function(){
	$('#dateStorageProlongationForm').css('display', 'block');
});

$('#dateStorageProlongationForm').ajaxForm({
	dataType: 'json',
	success: function( msg ) {
		$('#dateStorageProlongationForm').css('display', 'none');
		$('#dateStorageCurrent').html(msg['newDate']);
	}
});

$('#checkout_get_mail').on('shown.bs.modal', function() {
	setTimeout( function(){$('#checkout_get_mail input[name=email]').focus()}, 1000 );
	$('#checkout_get_mail').on('click', '.js-submit', function(){
		var input = $('#checkout_get_mail').find('input[name=email]');
		if( input.val() == '' ) {
			input.addClass('error');
			return false;
		} else {
			input.removeClass('error');
			$.ajax({
				url: '/checkout_success.php',
				data : {
					email : input.val(),
					action : 'set_email',
					ajax : 3,
				},
				dataType: 'json',
				success: function(msg){
					if( msg['error'] != undefined && msg.error == 0 ) {
						$('#checkout_get_mail').modal('hide');
					} else {
						input.addClass('error');
					}
				}
			});
		}
	});
});
$(function(){
	if ( $('#checkout_get_mail').length ) {
		$('#checkout_get_mail').modal('show');
	}
});

$(document).on({
	'mouseenter': function(e) {
		$('#main, #footer').css('opacity', '0.5');
	},
	'mouseleave': function(e) {
		$('#main, #footer').css('opacity', '');
	}
}, '#nav');

$(document).on('submit', '.hover_size form.select-front', function(e) {
	var el = $(this).closest('form.select-front');
	e.preventDefault();
	shoppingCart.addProducts({
		product_info: el.serializeArray(),
		success: function( msg ){
		// 	el.closest('.product-card-footer').removeClass('active');
		// 	$('html, body').animate({ scrollTop: 0 });
			$('#add-to-cart-modal').modal('show');
		}
	}, {
		checkDublicate: false
	});
});
$(document).on('change', '.hover_size form.select-front input[name="id[1]"]', function(e) {
	$(this).closest('form').trigger('submit');
});

// 63. ���� - ���������� ���� ����� � ������� ������������� https://yadi.sk/i/rrc--TTg3UDEzH - start
// $('#nav .dropdown-toggle').on('mouseenter', function() {
// 	var parent = $(this).parent();
// 	if( !parent.hasClass('open') ) {
// 		var that = this;
// 		$(this).dropdown('toggle');
// 		parent.one('mouseleave', function() {
// 			if( $(this).hasClass('open') ) {
// 				$(that).dropdown('toggle');
// 			}
// 		});
// 	}
// });


// css:
// #nav>ul>li {
//     padding-bottom: 20px;
//     margin-bottom: -20px;
// }
// 63. ���� - ���������� ���� ����� � ������� ������������� https://yadi.sk/i/rrc--TTg3UDEzH - end

function initYandexMaps(){

	if (document.getElementById('anta-yandex_maps')) {
		var myMap = new ymaps.Map("anta-yandex_maps", {
			center: [55.711243, 37.653538],
			zoom: 15
		});
	}
}

function initNewsHeroSlider() {
	seeFirstSlider();
	var alreadySee = [1];

	$('.js-promo-slider').slick({
		autoplaySpeed: 7000,
		autoplay:true,
        dots: true
	}).on('afterChange', function(slick, currentSlide){
		var slide = $('.js-promo-slider .slick-active.js-banner-item'),
			index = slide.attr('data-index');

		if(alreadySee.includes(index) || $(window).scrollTop() > 250){
			return false;
		}
		alreadySee.push(index);

		var id = slide.attr('data-banners-id'),
			name = slide.attr('data-banners-name'),
			position = slide.attr('data-index');

		window.dataLayer = window.dataLayer || [];
		dataLayer.push({
			'event': 'view_promotion',
			'ecommerce': {
				'items': [{
					'promotion_id': id,
					'creative_name': name,
					'creative_slot': position,
					'location_id': 'promo-slider',
					'quantity': '1'
				}]
			}
		});
	});


	$('.js-promo-slider .js-banner-item').click(function(){
		window.dataLayer = window.dataLayer || [];
		var id = $(this).attr('data-banners-id'),
			name = $(this).attr('data-banners-name'),
			position = $(this).attr('data-index');

		dataLayer.push({
			'event': 'select_promotion',
			'ecommerce': {
				'items': [{
					'promotion_id': id,
					'creative_name': name,
					'creative_slot': position,
					'location_id': 'promo-slider',
					'quantity': '1'
				}]
			}
		});
	});

	function seeFirstSlider(){
		window.dataLayer = window.dataLayer || [];
		var firstTopSlider = $('.js-promo-slider .js-banner-item[data-index="1"]'),
			id = firstTopSlider.attr('data-banners-id'),
			name = firstTopSlider.attr('data-banners-name'),
			position = firstTopSlider.attr('data-index');

		dataLayer.push({
			'event': 'view_promotion',
			'ecommerce': {
				'items': [{
					'promotion_id': id,
					'creative_name': name,
					'creative_slot': position,
					'location_id': 'promo-slider',
					'quantity': '1'
				}]
			}
		});
	}
}

function initMainPageBottomSlider(){
	$('.js-main-page-bottom-slider').each(function(){$(this).slick({ dots: true });})
}

function initHeader() {
	$('.contacts-only-mobile .see-contacts').click(function () {
		$('#contact-modal').addClass('active');
		stopBodyScroll(true);
	});
	$('.castom-tablet-mobile-modal .close-modal>span, .castom-tablet-mobile-modal .js-close-modal').click(function() {
		stopBodyScroll(false);
		$(this).closest('.castom-tablet-mobile-modal').removeClass('active');
	});
	$('.js-burger').on('click', function(e) {
		e.preventDefault();
		if ( !$('body').hasClass('menu-open') ) {
			$('.pre-open').addClass('open');
			$('#header').addClass('see');
		}else{
			$('#header').removeClass('see');
		}
		$('body').toggleClass('menu-open');
		$('.modal.in').each(function() {
			$(this).modal('hide');
		});
	});
	$('.dropdown-toggle ', '#nav').on('click', function(e){
		if ( $(window).innerWidth() < 1024 ) {
			e.preventDefault();
			$(this)
				.closest('li')
				.toggleClass('open')
				.siblings('li')
				.removeClass('open');
		}
	});
	$('#nav .bg').on('click', function() {
		$('body').removeClass('menu-open');
	});
	$('.nav-inner-list h4').on('click', function() {
		$(this)
			.closest('li')
			.toggleClass('open')
			.siblings('li')
			.removeClass('open');
	});
};

//scroll start

function stopBodyScroll(yesBool) {
	if(yesBool){
		$('body').addClass('not-scroll');
	}else{
		$('body').removeClass('not-scroll');
	}
};

$(function () {
	var lastPositionScrool = window.pageYOffset;

	$(window).scroll(function () {
		if ($(window).scrollTop() > 200) {
			$('#scroll_top').show();
		} else {
			$('#scroll_top').hide();
		}

		if (viewport().width <= 1024) {
			if(window.pageYOffset>50){
				$('#wrapper').addClass('mobile-place');
				$('#header').addClass('fixed');
				if( lastPositionScrool > window.pageYOffset){
					$('#header').addClass('see');
				}else{
					$('#header').removeClass('see');
				}
			}else{
				$('#wrapper').removeClass('mobile-place');
				$('#header').removeClass('fixed');
			}
			lastPositionScrool = window.pageYOffset;
		}
	});

	$('#scroll_top').click(function () {
		$('html, body').animate({scrollTop: 0}, 600);
		return false;
	});

	//��� �������� popup ����� �������� � ������� �������� �� ������
	$("#add-to-cart-modal").on("hidden.bs.modal", function () {
		$('.product-small-preview__wrapper',$(this)).html('');
	});
});

//scroll end

function seeMyPassword(ico) {
	var passField = $('input[name="password"]',$(ico).closest("div"));

	if(!passField){
		return false;
	}
	if ($(passField).attr('type') == 'password') {
		$(ico).addClass('view');
		$(passField).attr('type', 'text');
	} else {
		$(ico).removeClass('view');
		$(passField).attr('type', 'password');
	}
	return false;
}

//��� ������� � ��������
if(viewport().width < 1024) {
	//������ ��������� ������������� ����������� �������� ������
	if(+localStorage.getItem('showOneProductAtLine')){
		$('.mobile-view-products-switch .view-products-switcher').addClass('show-one');
		$('.catalog-section .product-list').addClass('show-one-at-line');
		$('.catalog-section .product-list img').each(function(){
			$(this).attr('src',$(this).attr('src').replace('images_230xh','bimages'));
		});
	}else{
		localStorage.setItem('showOneProductAtLine',''+0);
	}

	$(function () {
		//������������ � 2 ������� � ��� �� 1
		$('.mobile-view-products-switch .view-products-switcher').click(function () {
			localStorage.setItem('showOneProductAtLine', +!+localStorage.getItem('showOneProductAtLine') + '');
			$(this).toggleClass('show-one');
			$('.catalog-section .product-list').toggleClass('show-one-at-line');

			if (+localStorage.getItem('showOneProductAtLine')) {
				changeImgFolder('images_230xh', 'bimages');
			} else {
				changeImgFolder('bimages', 'images_230xh');
			}
		});

		/**
		 * ������� ����� ����������� - � ��������� ���� �� �������
		 * @param from
		 * @param to
		 */
		function changeImgFolder(from, to) {
			$('.catalog-section .product-list img').each(function () {
				$(this).attr('src', $(this).attr('src').replace(from, to));
			});
		}

		$('.js-submenu_title a').click(function(){
			$(this).closest('li').children('ul').slideToggle();
			return false;
		});

		//��� ������� � �������� �� ���������� ������ ������� ������
		if($('.breadcrumbs')) {
			try {
				$('.breadcrumbs')[0].scrollLeft = $('.breadcrumbs')[0].scrollWidth - 100;
			}catch (e) {}
		}
	});
}else{
	//������ ��� ��������
	$(function(){
		//������ � ���������� ������� ������� � �������
		$('.basket-drop-list').slick({
			dots: true,
			arrows: false,
			infinite: false,
			slidesToShow: 4,
			slidesToScroll: 4,
			variableWidth: true
		});
	});
}

function diffDateSubtract(date1, date2) {
	return date2 - date1;
}

function padNumber(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function getDays( cnt ) {
	if ( cnt == 1 ) {
		return '����';
	} else if ( cnt < 5 ) {
		return '���';
	} else {
		return '����';
	}
}

function setTimer( end_date, element, holder, reload ) {
	let timer_show = $(element);
	let holderElement = $(holder);
	let end_date_str = end_date.full_year + '-' + end_date.month + '-' + end_date.day + 'T' + end_date.hours + ':' + end_date.minutes + ':' + end_date.seconds;

	let timer = setInterval(function () {
		let now = new Date();
		let date = new Date(end_date_str);
		let ms_left = diffDateSubtract(now, date);
		if (ms_left <= 0) {
			if ( reload ) {
				window.location.reload();
			}
			clearInterval(timer);
			holderElement.hide();
		} else {
			let res = new Date(ms_left);
			let str_timer = '';
			if ( res.getUTCDate() > 1 ) {
				str_timer = res.getUTCDate() - 1 + ' ' + getDays(res.getUTCDate() - 1) + ' ';
			}
			str_timer = str_timer + padNumber(res.getUTCHours(), 2) + ':' + padNumber(res.getUTCMinutes(), 2) + ':' + padNumber(res.getUTCSeconds(), 2);
			timer_show.html( str_timer );
			holderElement.show();
		}
	}, 1000);
}

function setExtraTopTimer() {
	if ( $('.top_popup-timer').length == 0 ) return;
	let $holderElement = $('.top_popup-timer');
	let endDateStr = $holderElement.data('end_time');

	let timer = setInterval(function () {
		let now = new Date();
		let date = new Date(endDateStr);
		let msLeft = diffDateSubtract(now, date);
		if (msLeft <= 0) {
			clearInterval(timer);
			$holderElement.hide();
		} else {
			let res = new Date(msLeft);
			let strTimer = '';
			if ( res.getUTCDate() > 1 ) {
				strTimer = res.getUTCDate() - 1 + ' ' + getDays(res.getUTCDate() - 1) + ' ';
			}
			strTimer = strTimer + padNumber(res.getUTCHours(), 2) + ':' + padNumber(res.getUTCMinutes(), 2) + ':' + padNumber(res.getUTCSeconds(), 2);
			$holderElement.html( strTimer );
			$holderElement.show();
		}
	}, 1000);
}

if ( $('#top-popup-timer').length ) {
	let end_date = {
		"full_year": "2021", // ���
		"month": "04", // ����� ������
		"day": "17", // ����
		"hours": "00", // ���
		"minutes": "00", // ������
		"seconds": "00" // �������
	}
	setTimer(end_date, '#top-popup-timer', '.top_popup-panel', false);
}

if ( $('#j-available-timer').length ) {
	let end_date = {
		"full_year": $('#j-available-timer').data('year'),
		"month": $('#j-available-timer').data('month'),
		"day": $('#j-available-timer').data('day'),
		"hours": $('#j-available-timer').data('hours'),
		"minutes": $('#j-available-timer').data('minutes'),
		"seconds": $('#j-available-timer').data('seconds')
	};
	setTimer(end_date, '#j-available-timer', '#product-timer-holder', true);
}

$('.j-categories-descr-show').each(function() {
	if ( $(this).prev().outerHeight() > 50 ) {
		$(this).removeClass('hidden').prev().addClass('short');
	}
	$(this).on('click', function() {
		$(this).addClass('hidden').prev().removeClass('short');
	});
});

//��������� ������ � input type="file"
$(function(){
	inputTypeFileInit();
	setExtraTopTimer();
});

function inputTypeFileInit(){
	var defaultText = '�������� ����';
	$('[type="file"]').each(function(index){
		if($(this).data('init')){
			return true;
		}

		var id = 'input__file__' + index,
			helpText = $(this).attr('placeholder') || defaultText;

		$(this).attr('id',id).addClass('input__file').data('init', 1);
		$(this).replaceWith(
				'<div class="input__wrapper">\n' +
						this.outerHTML +
				'   <label for="'+ id +'" class="input__file-button">\n' +
				'      <span class="input__file-text">'+ helpText +'</span>\n' +
				'      <span class="input__file-icon-wrapper">' +
									'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20pt" height="20pt" viewBox="0 0 20 20" version="1.1">\n' +
										'<g>\n' +
										'<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 17.8125 15.15625 L 16.5625 15.15625 L 16.5625 18.59375 L 3.4375 18.59375 L 3.4375 15.15625 L 2.1875 15.15625 L 2.1875 19.84375 L 17.8125 19.84375 Z M 17.8125 15.15625 "/>\n' +
										'<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 9.375 0.15625 L 9.375 11.640625 L 5.4375 7.6875 L 4.546875 8.578125 L 10 14.03125 L 15.453125 8.578125 L 14.5625 7.6875 L 10.625 11.640625 L 10.625 0.15625 Z M 9.375 0.15625 "/>\n' +
										'</g>\n' +
									'</svg>' +
				'			 </span>\n' +
				'      <span class="input__file-button-text btn">����������</span>\n' +
				'   </label>\n' +
				'</div>'
		);
	});

	$('[type="file"]').each(function(){
		if($(this).data('init-change')){
			return true;
		}
		$(this).data('init-change',1).change(function(){
			var countFiles = this.files && this.files.length >= 1 ? this.files.length : '',
				text = countFiles ? '������� ������: ' + countFiles : $(this).attr('placeholder') || defaultText;

			$('.input__file-text', $(this).closest('.input__wrapper')).text(text)
		});
	});
}

function handlerSendDiscountCouponOnEmail($modal) {

	const email = $modal.find('[name="email_address"]').val();

	$.ajax({
		url: '/sent_coupon_500_5day.php',
		data : {
			email : email,
			ajax : 1,
		},
		dataType: 'json',
	});
	$modal.find('form').hide(300);
	$modal.find('h2').text('�������� �� ������ ��� �� �����!');
	$modal.find('.close').on('click', () => {
		window.location.reload();
	})
	if ( window.ga ) ga('send', 'event', 'Popup', 'Get10DiscountWithEmail', 'Coupon_Sent', {'nonInteraction': 1});
	return true;
}

function initSendDiscountCouponOnEmail() {
	$(document).find('#discount-modal').on('submit', (event) => {
		event.preventDefault();
		const modal = event.currentTarget;
		const $modal = $(modal);
		handlerSendDiscountCouponOnEmail($modal);
		if(typeof ym === "function") {
			ym(45671829, 'reachGoal', 'Popup_10Discount_EmailEntered');
		}
		if(typeof ga === "function") {
			ga('send', 'event', 'Popup', 'Popup_10Discount_EmailEntered');
		}
	});
}

function initDiscountModalTimer() {
	const hasDiscountButton = $(document).find(".js-discount-button").length;
	if (hasDiscountButton > 0 && window.PROSKTR.visitCounter == 2) {
		setTimeout( () =>{
			$('.js-discount-button').addClass('active');
		}, 10000);
	}
}

$(document).on('click', '.js-discount-button', function(){
	$('#discount-modal').modal('show');
	if ( typeof ga === "function") {
		ga('send', 'event', 'Popup', 'Get10DiscountWithEmail', 'Show_Popup', {'nonInteraction': 1});
		ym(45671829,'reachGoal','Popup_10Discount_Show');
		ga('send', 'event', 'Popup', 'Popup_10Discount_Show');
	}
	if(typeof ym ==="function"){
		ym(45671829,'reachGoal','Popup_10Discount_Show');
	}
});

function loadLazyImages() {
	var productsWrap = $('.product-list-slider-index, .product-list-slider, .recently-viewed-section,.js-main-page-slider');
	$('img.js-catolog-lazy', productsWrap).each(function() {
		$(this).removeClass('js-catolog-lazy').attr('src', $(this).data('original'));
	});	
}

$(window).one('scroll', function () {
	loadLazyImages();
});
$(window).one('mousemove', function () {
	loadLazyImages();
});
$(window).one('click', function () {
	loadLazyImages();
});

if ( window.ga ){
	$(window).scroll(function() {
		if( $('.product-panel-section.block-collection').length > 0 && $(window).scrollTop() > $('.product-panel-section.block-collection').offset().top ) {
			ga('send', 'event', 'Info_PrInfo_Blocks', 'Show_Block_Collections', $(this).attr('data-send'), {'nonInteraction': 1});
		}
		if( $('.product-panel-section.block-sport').length > 0 && $(window).scrollTop() > $('.product-panel-section.block-sport').offset().top ) {
			ga('send', 'event', 'Info_PrInfo_Blocks', 'Show_Block_Sports', $(this).attr('data-send'), {'nonInteraction': 1});
		}
		if( $('.product-panel-section.block-alsobuy').length > 0 && $(window).scrollTop() > $('.product-panel-section.block-alsobuy').offset().top ) {
			ga('send', 'event', 'Info_PrInfo_Blocks', 'Show_Block_AlsoBuys', $(this).attr('data-send'), {'nonInteraction': 1});
		}
	});
	$(document).on('click', '.product-panel-section.block-collection', function(){
		ga('send', 'event', 'Info_PrInfo_Blocks', 'Click_Block_Collections', $(this).attr('data-send'), {'nonInteraction': 1});
	});
	$(document).on('click', '.product-panel-section.block-sport', function(){
		ga('send', 'event', 'Info_PrInfo_Blocks', 'Click_Block_Sports', $(this).attr('data-send'), {'nonInteraction': 1});
	});
	$(document).on('click', '.product-panel-section.block-alsobuy', function(){
		ga('send', 'event', 'Info_PrInfo_Blocks', 'Click_Block_AlsoBuys', $(this).attr('data-send'), {'nonInteraction': 1});
	});
}

$('.j-show-more-li').on('click', function(){
	$(this).closest('li').addClass('hidden').siblings().removeClass('hidden');
});

$(function(){
	initCatalog();
	initMainPage();
	initProductListStatistics();

	function initProductListStatistics(){
		$('.product-list').on('click','.js-product-link ',function(){
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				event: 'select_item',
				ecommerce: {
					items: [{
						item_name: $(this).attr('data-products_name'),
						item_id: $(this).attr('data-products_id'),
						price: $(this).attr('data-current_price'),
						item_brand: $(this).attr('data-manufacturers_name'),
						item_category: $(this).attr('data-polveshi'),
						item_category2: $(this).attr('data-cattovara_main'),
						item_category3: $(this).attr('data-cattovara_medium'),
						item_category4: $(this).attr('data-cattovara'),
						item_list_name: $(this).attr('data-uriName'),
						index: 1,
						quantity: 1
					}]
				}
			});
		});
	}
});

function initCatalog(){
	// $('.js-categories-slider').slick({
	// 	dots: false,
	// 	arrows: true,
	// 	slidesToShow: 4,
	// 	slidesToScroll: 3,
	// 	responsive: [
	// 		{
	// 			breakpoint: 1540,
	// 			settings: {
	// 				slidesToShow: 3,
	// 				slidesToScroll: 1,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 1400,
	// 			settings: {
	// 				slidesToShow: 3,
	// 				slidesToScroll: 1,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 1200,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				slidesToScroll: 1,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 1024,
	// 			settings: {
	// 				slidesToShow: 4,
	// 				slidesToScroll: 1,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 1023,
	// 			settings: {
	// 				slidesToShow: 5,
	// 				slidesToScroll: 1,
	// 				infinite: false,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 773,
	// 			settings: {
	// 				slidesToShow: 4,
	// 				slidesToScroll: 1,
	// 				infinite: false,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 615,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				centerMode: true,
	// 				slidesToScroll: 1,
	// 				infinite: false,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 470,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				centerMode: true,
	// 				slidesToScroll: 1,
	// 				infinite: false,
	// 			},
	// 		},

	// 	]
	// });
}
function initMainPage(){
	initBlogSlider();
	initStatistics();

	function initBlogSlider(){
		$('.js-blog-slider').slick({
			dots: true,
			arrows: false,
			slidesToShow: 2,
			slidesToScroll: 2,
			responsive: [
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				}
			]
		})
	}

	function initStatistics(){
		var sendManStatistics = false,
			sendWomanStatistics = false,
			sendFormLoginCode = false,
			sendFormLoginByCall = false,
			sendClickSubscribe = false;

		$('.js-get-code-send-statistics').click(function(){
			if(sendFormLoginCode){
				return true;
			}
			sendFormLoginCode = true;
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({'event': 'webit_form_loginCode'});
		});
		$('.js-login-by-call-send-statistics').click(function(){
			if(sendFormLoginByCall){
				return true;
			}
			sendFormLoginByCall = true;
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({'event': 'webit_form_loginbyCall'});
		});
		$('.js-subscribe-submit-send-statistics').click(function(){
			if(sendClickSubscribe){
				return true;
			}
			sendClickSubscribe = true;
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({'event': 'webit_click_subscribe'});
		});


		$('.js-send-statistics-man .js-product-link').click(function(){
			if(typeof ym==="function") {
				ym(45671829,'reachGoal','Mainpage_Blocks_Popular_Man_Click');
			}
			if(typeof ga==="function") {
				ga('send', 'event', 'Mainpage_Blocks', 'Mainpage_Blocks_Popular_Man_Click');
			}
		});
		$('.js-send-statistics-woman .js-product-link').click(function(){
			if(typeof ym==="function") {
				ym(45671829,'reachGoal','Mainpage_Blocks_Popular_Woman_Click');
			}
			if(typeof ga==="function") {
				ga('send', 'event', 'Mainpage_Blocks', 'Mainpage_Blocks_Popular_Woman_Click');
			}
		});
		if($('.js-send-statistics-man').length && $('.js-send-statistics-woman').length) {
			document.addEventListener('scroll', function () {
				const posTopManBlock = $('.js-send-statistics-man')[0].getBoundingClientRect().top;
				const posTopWomanBlock = $('.js-send-statistics-woman')[0].getBoundingClientRect().top;

				if (!sendManStatistics && (posTopManBlock + 300) < window.innerHeight) {
					sendManStatistics = true;
					if (typeof ym === "function") {
						ym(45671829, 'reachGoal', 'Mainpage_Blocks_Popular_Man_Show');
					}
					if (typeof ga === "function") {
						ga('send', 'event', 'Mainpage_Blocks', 'Mainpage_Blocks_Popular_Man_Show');
					}
				}
				if (!sendWomanStatistics && (posTopWomanBlock + 300) < window.innerHeight) {
					sendWomanStatistics = true;
					if (typeof ym === "function") {
						ym(45671829, 'reachGoal', 'Mainpage_Blocks_Popular_Woman_Show');
					}
					if (typeof ga === "function") {
						ga('send', 'event', 'Mainpage_Blocks', 'Mainpage_Blocks_Popular_Woman_Show');
					}
				}
			});
		}
	}
}

$('#b2b-modal form').on('submit', function(e){
	e.preventDefault();
	let $form = $(this);
	$form.find('input[name=method]').val($('#b2b-modal h2').text());
	let data = $form.serializeArray();
	$.ajax({
		url: "/ajax.php?type=antab2b",
		data: data,
		dataType: 'json',
		method: 'post',
		success: function (msg) {
			if ( msg.done == 'done' ) {
				$form.hide().closest('.modal-body').append('<div>���� ������ �������.</div>');
			}
		}
	});
});

function initLoginForm() {
	return false;
	$('.j-get-pass').on('click', function(){
		if ( $(this).hasClass('disabled') ) return;
		$(this).addClass('disabled');
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		$('.j-form-error', $form).html('');
		$('#loginLoading').text('������ �����������...');
		$.ajax({
			url: "/ajax_customer.php?action=checkAndSendPassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				login: email_address,
			},
			error: function(msg) {
				$button.removeClass('disabled');
				$('.j-form-error', $form).html(msg.err_mes);
				$('#loginLoading').text('');
			},
			success: function(msg) {
				$button.removeClass('disabled');
				$('#loginLoading').text('');
				if ( msg.result == 1 ) {
					$button.addClass('hidden');
					$('.j-code-holder, .j-login-btn, .j-reget-pass', $form).removeClass('hidden');
					if ( !isNaN(parseInt(email_address)) ) {
						$('.j-reget-pass-call', $form).removeClass('hidden');
					}
				}
				$('.j-form-error', $form).html(msg.err_mes);
			}
		});
	})
	$('.j-reget-pass').on('click', function(){
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		$('.j-form-error', $form).html('');
		$.ajax({
			url: "/ajax_customer.php?action=resendNamePassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				login: email_address,
			},
			error: function(msg) {
				$('.j-form-error', $form).html(msg.err_mes);
			},
			success: function(msg) {
				$('.j-form-error', $form).html(msg.err_mes);
			}
		});
	});
	$('.j-reget-pass-call').on('click', function(){
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		$('.j-form-error', $form).html('');
		$.ajax({
			url: "/ajax_customer.php?action=recallWithPassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				login: email_address,
			},
			error: function(msg) {
				$('.j-form-error', $form).html(msg.err_mes);
			},
			success: function(msg) {
				$('.j-form-error', $form).html(msg.err_mes);
			}
		});
	});
	$('.j-login-btn').on('click', function(){
		if ( $(this).hasClass('disabled') ) return;
		$(this).addClass('disabled');
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name=phone]').val();
		let email_address = $form.find('input[name=email_address]').val();
		let passCode = $form.find('input[name=passCode]').val();
		$('.j-form-error', $form).html('');
		customer.loginByPassCode({
			customerInfo: {
				passCode: passCode,
				login: email_address
			},
			error: function(msg) {
				$button.removeClass('disabled');
				$('.j-form-error', $form).html(msg.err_mes);
			},
			success: function(msg) {
				if ( $('#login').length ) {
					$("#login").modal('hide');
				}
				if ( PROSKTR.content == 'login' ) {
					window.location.href = 'account.php';
				}
				if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
					window.location.reload();
				}
				if( msg.result==1 && msg.err_code=='login_ok' ) {
					console.log(this);
					var headerElement = $('.meta-nav__menulist__item.account');
					headerElement.addClass('logined');
					$('.mobile-nav__tools .account').show();
					if ( msg.name )
					{
						var name = msg.name.split( ' ' );
						name = name[0];
						$('.meta-nav__menulist__link span', headerElement).text(name);
					}
					shoppingCart.reloadPopup();
					if(msg.customerNeedConfirm != undefined){
						$('body').append(msg.customerNeedConfirm).popup().popup({
							"start":true,
							"opener":false,
							"popup_holder":"#customerNeedConfirm",
						});
					}
					customer.logined = true;
					customer.info.customer_id = parseInt(msg.customers_id);
					customer.info.asLastOrder = msg.may_last_order;
					customer.info.name = msg.name;
					customer.info.phone = msg.phone;
					customer.info.lastname = msg.lastname;
					if( typeof customer.afterLogin == 'function' ) {
						customer.afterLogin(null, msg, null);
					}
					if( typeof callback == 'function' ) {
						callback( msg );
					}
					cookieObj.setCookie('customerid', parseInt(msg.customers_id) );
					if($('.login-btn_trigger_reload').length > 0){
						location.reload();
					}
				}
			}
		});
	});
}

if ( $('.hell-subscribe').length ) {
	$('.hell-subscribe').on('submit', function(e){
		e.preventDefault();
		let $form = $(this);
		let $emailEl = $('input[name=email_address]', $form);
		let $phoneEl = $('input[name=telephone]', $form);
		if ( !$(this).hasClass('form-valid-error') ) {
			if ( $emailEl.val() != '' ) {
				customer.checkEmail({
					email: $emailEl.val(),
					email_exists: function( msg ) {
						$emailEl.addClass('valid-error').closest('.j-value').find('.validation-description').html('<div class="error">��� e-mail ��� ���������������</div>');
					},
					email_not_exists: function( msg ) {
						$emailEl.removeClass('valid-error').closest('.j-value').find('.validation-description').html('');
					},
					error: function( msg ) {
						$emailEl.addClass('valid-error').closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					}
				});
			}

			if ( $phoneEl.val() != '' ) {
				customer.checkPhone({
					phone: $phoneEl.val(),
					phone_exists: function( msg ) {
						$phoneEl.addClass('valid-error').closest('.j-value').find('.validation-description').html('<div class="error">��� ������� ��� ���������������</div>');
					},
					phone_not_exists: function( msg ) {
						$phoneEl.removeClass('valid-error').closest('.j-value').find('.validation-description').html('');
					},
					error: function( msg ) {
						$phoneEl.addClass('valid-error').closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					}
				});
			}
		}

		if ( $('.valid-error', $form).length ) {
			$form.addClass('form-valid-error');
			$('.j-hell-subscribe-submit').removeClass('loading');
			return;
		} else {
			$form.removeClass('form-valid-error');
			customer.create({
				customer_info: {
					customers_email_address: $form.find('input[name=email_address]').val(),
					customers_telephone: $form.find('input[name=telephone]').val(),
					customers_create_source: 'helloween',
					customers_newsletter: 1,
	
				},
				success: function( msg ) {
					$form.addClass('hidden').next('.success').removeClass('hidden');
					//������
					if(typeof ym === "function") {
						ym(45671829, 'reachGoal', 'Email_discount');
					}
					//Google Ads
					if(typeof ga === "function") {
						ga('send', 'event', 'Email', 'discount');
					}
				},
				error: function( msg ) {
				}
			});
			$('.j-hell-subscribe-submit').removeClass('loading');
		}
	});

	$('.j-hell-subscribe-submit').on('click', function(e){
		e.preventDefault();
		if ( $(this).hasClass('loading') ) {
			return;
		}
		$(this).addClass('loading');
		let $form = $(this).closest('form');
		let $emailEl = $('input[name=email_address]', $form);
		let $phoneEl = $('input[name=telephone]', $form);
		if ( $emailEl.val() == '' && $phoneEl.val() == '' ) {
			$form.addClass('form-valid-error');
			$(this).removeClass('loading');
			return;
		}

		$form.trigger('submit');
	});
}

$('#mindbox-login').on('submit', 'form', function(e){
	e.preventDefault();
});
$('#createAccountFormMindboxHolder').on('submit', 'form', function(e){
	e.preventDefault();
});

function MindboxFormSubmit( id ) {
// $('#mindbox-login').on('submit', 'form', function(e){
	// e.preventDefault();
	let hasEmpty = false;
	let $form = $('#' + id);
	// let $form = $(this);
	let $btn = $('.btn-submit', $form);

	if ( $btn.hasClass('disabled') ) return;
	$btn.addClass('disabled').addClass('loading');

	$('input[type=text]', $form).each(function(){
		if ( $(this).val() == '' ) {
			$(this).addClass('error');
			hasEmpty = true;
		}
	});
	if ( hasEmpty ) {
		$btn.removeClass('disabled').removeClass('loading');
		return;
	}
	$.ajax({
		url: '/ajax_mindbox.php',
		type: 'POST',
		data: $form.serializeArray(),
		dataType: 'json',
		context: this,
		success: function( msg ) {
			$('#mindbox-login').html(msg.html);
			if ( msg.login ) {
				$('#not-registered-popup, .js-not-registered-popup').fadeOut();
				$('#login').modal('hide');
				customer.afterLogin(null, msg, null);
				if ( PROSKTR.content == 'shopping_cart' ) {
					window.location.reload();
				} else {
					window.location.href = '/account.php';
				}
			}
		}
	}).fail(function() {
		$btn.removeClass('disabled').removeClass('loading');
	});
// });
}
$('.first-mindbox-login-form input[name=phone]').on('change', function(){
	let phone = $(this).val();
	$('#login-form-call input[name=phone]').val(phone);
});

function loginFromCall() {
	let $form = $('#login-form-call');
	let phone = $('input[name=phone]', $form).val();
	if ( phone == '' ) {
		return;
	}
	$.ajax({
		url: "/ajax_customer.php?action=checkAndCallWithPassCode&ajax=1",
		type: 'POST',
		dataType: 'json',
		data: $form.serializeArray(),
		success: function(msg) {
			$('.j-form-error', $form).html(msg.err_mes);
			if ( msg.result ) {
				$('#login-form-call .j-code-holder').removeClass('hidden');
				$('#login-form-call .j-get-pass').addClass('hidden');
				$('#login-form-call .j-login-btn').removeClass('hidden');
			}
		}
	});
};
$('#login-form-call .j-login-btn').on('click', function(){
	if ( $(this).hasClass('disabled') ) return;
	$(this).addClass('disabled');
	let $form = $(this).closest('form');
	let $button = $(this);
	let phone = $form.find('input[name=phone]').val();
	let passCode = $form.find('input[name=passCode]').val();
	$('.j-form-error', $form).html('');
	customer.loginByPassCode({
		customerInfo: {
			passCode: passCode,
			phone: phone
		},
		error: function(msg) {
			$button.removeClass('disabled');
			$('.j-form-error', $form).html(msg.err_mes);
		},
		success: function(msg) {
			window.location.reload();
		}
	});
});

function initMindboxRegistration() {
	let $form = $('.j-mindbox-registration-from');

	$form.on('submit', function(e) {
		e.preventDefault();
		if ( $form.hasClass('form-valid-error') ) {
			return;
		}

		let data = $form.serializeArray();
		let code = $form.find('input[name=code]').val();
		$form.find('.j-reg-result').html('');
		data.push({name: 'ajax', value: '3'});
		$.ajax({
			url: '/loyalty_registration',
			type: 'POST',
			data: data,
			dataType: 'json',
			context: this,
			success: function( msg ) {
				if ( msg.done ) {
					if ( code == '' ) {
						$form.find('.j-code-row').removeClass('hidden');
					} else {
						$form.html(msg.message);
					}
				}
				if ( code == '' ) {
					$form.find('.j-reg-result').html(msg.message);
				} else {
					$form.find('.j-code-row').find('.alidation-description').html(msg.message);
				}
			}
		});
	});
}

initMindboxRegistration();