ShoppingCart.prototype.setQuantityNPrice = function( msg ) {
	msg = msg || false;
	if( msg ){
		sendStatistics(msg.products);
		renderProductList(msg);
		renderShoppingCardProductsList(msg);
		renderShoppingCardBasketTotal(msg);
		$('.js-basket-modal-total').text(msg.total_price);
		$('.js-basket-modal-discount-box').fadeOut();
		$('.js-basket-count').text(msg.count_contents);
		$('.js-data-basket-count').attr('data-basket-count',msg.count_contents);

		if ( msg.mindboxCashback ) {
			$('.j-cart-cashback').text(msg.mindboxCashback).closest('dl').removeClass('hidden');
		} else {
			$('.j-cart-cashback').text('0').closest('dl').addClass('hidden');
		}
	}

	function renderProductList(answer){
		var productsHtml = $.render[ 'v1/basket_modal_product_list' ].render( answer.products, { currencies: window.PROSKTR.currencies } );

		$('.js-basket-modal-products-list').html(productsHtml);
	}

	function renderShoppingCardBasketTotal(){
		var isShoppingCardPage = $('.content-shopping_cart').length;
		if(!isShoppingCardPage){
			return true;
		}
		$('.j-cart-content-total').text( msg.total_price + ' ' + window.PROSKTR.currencies.rightMin );
		$('.j-cart-full-total').text( msg.total_price + ' ' + window.PROSKTR.currencies.rightMin );
		$('.j-product-counts-full').text( msg.count_contents + ' ' + msg.countInCartGoods );
		$('.j-cart-delivery-total').text( '���������');
		$('.j-cart-avg-prepay').text( msg.avgFullprepayDiscountPct );
		$('.j-cart-sum-prepay').text( msg.fullPrepayDicountSum );
		$('.j-cart-prepay-total').text( msg.prepay_total );
		var discount = parseInt( msg.real_total ) - msg.total_price;
		$('.j-cart-discount').text( ( discount > 0 ? discount : 0 ) + ' ' + window.PROSKTR.currencies.rightMin );

		$('.j-cart-content-count').text(msg.count_contents);
		$('.j-cart-content-count-data').attr('data-amount', msg.count_contents);
		if ( msg.count_contents > 0 ) {
			$('.not-empty-cart').removeClass('hidden');
			$('.empty-cart').addClass('hidden');
		} else {
			$('.empty-cart').removeClass('hidden');
			$('.not-empty-cart').addClass('hidden');
			$('.fixed-basket-totals').empty();
		}
		if ( msg.total_price > 20 && msg.total_price < 2000 ) {
			$('.j-to-order').addClass('hidden');
			$('.j-min-order').removeClass('hidden');
		}

		cookieObj.setCookie('customerorderCnt',  msg.count_contents );
		$('#form-get-delivery').submit();
	}

	function renderShoppingCardProductsList(answer){
		if ( $('.content-shopping_cart').length == 0 ) {
			return true;
		}
		var productsHtml = $.render[ 'show_product_tpl' ].render( answer.products, { currencies: window.PROSKTR.currencies } );
		var productsSideHtml = $.render[ 'edit_product_tpl' ].render( answer.products, { currencies: window.PROSKTR.currencies } );
		$('.basket-list').html('');
		$('.cart-products-small').html('');
		$('.basket-list').append(productsHtml);
		$('.cart-products-small').append(productsSideHtml);
		$('#change-item-modal .close').trigger('click');
		if ( answer.mindbox_coupon_added ) {
			$('.js-mindbox-coupon-applied').addClass('active');
			$('.js-mindbox-coupon-not-applied').removeClass('active');
		} else if ( answer.mindbox_coupon_error ) {
			$('.js-mindbox-coupon-error').text(answer.mindbox_coupon_error);
			$('.js-mindbox-coupon-applied').removeClass('active');
			$('.js-mindbox-coupon-not-applied').addClass('active');
		}
		$('[data-toggle="tooltip"]').tooltip({html:true});
	}

	function sendStatistics(products){
		if(Array.isArray(products) && typeof mindbox === "function"){
			var productList = [];

			for(var i = 0; i<products.length; i++){
				var currentProduct = products[i];

				productList.push({
					product: {
						ids: {
							website: currentProduct.warehouse_artikul2,
						}
					},
					count: currentProduct.quantity,
					pricePerItem: currentProduct.final_price
				});
			}
			mindbox("async", {
				operation: "WebsiteSetCart",
				data: {
					productList: productList,
				},
				onSuccess: function() { },
				onError: function(error) { }
			});
		}
	}
}
ShoppingCart.prototype.addProductCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		$('.js-add-product').removeClass('m-preloader');
		this.setQuantityNPrice(msg);
	}
}
Listing.prototype.settings.formShowResponse = function(responseText, statusText, additParam, jqForm) {
	var productsList = [];
	for(var product of responseText.productsList){
		if( product.products_images_count>0){
			product.imgGallery = [];
			for (var i=1; i<=product.products_images_count; i++){
				product.imgGallery.push({
					key:i,
					products_image: product.products_bimage.replace("_1", "_"+i),
					products_name: product.products_name
				});
			}
		}
		productsList.push(product);
	}

	var productsWrap = $('.js-catalog-products-list'),
		productsHtml = $.render[ 'v1/listing_products' ].render( productsList, {
			currencies: window.PROSKTR.currencies,
			NumToRu_declination: function NumToRu_declination(number,one,end_of_two_three_four,other){
				var number_string = number+'',
					last_numeric = number_string.slice(-1),
					is_11 = number_string.slice(-2) === '11';

				if(is_11){
					return end_of_two_three_four;
				}
				if(last_numeric === "1" ){
					return one;
				}
				if(last_numeric==="2"||last_numeric==="3"||last_numeric==="4"){
					return end_of_two_three_four;
				}
				return other;
			},
		});
	productsWrap.html(productsHtml);
	window.listing.setFiltrs( responseText.filtrs );
	if(responseText.pagination.pageCount > 1 && $('.js-pagination-page-list').length ){
		$('.js-pagination-page-list').html(responseText.pagination.html);
	}else {
		$('.js-pagination-page-list').html('');
	}
	if(responseText && responseText.pagination && responseText.pagination.allItems ) {
		var productsCount = responseText.pagination.allItems
		$('.js-listing-products-count').text(productsCount + ' ' + NumToRu_declination(productsCount, '�����', '������', '�������'));
	}

	function NumToRu_declination(number,one,end_of_two_three_four,other){
		var number_string = number+'',
			last_numeric = number_string.slice(-1),
			is_11 = number_string.slice(-2) === '11';

		if(is_11){
			return end_of_two_three_four;
		}
		if(last_numeric === "1" ){
			return one;
		}
		if(last_numeric==="2"||last_numeric==="3"||last_numeric==="4"){
			return end_of_two_three_four;
		}
		return other;
	}
};

Listing.prototype.setFiltrs = function( filtrs ) {
	for(var filtrId in filtrs) {
		var filtrInfo = filtrs[filtrId];

		if (filtrId === '') {
			return true;
		}

		var filterFormEl = $('#filtr-form'),
			filtrEl = $('#' + filtrId, filterFormEl),
			filtrValues = filtrInfo.values;

		for (var groupName in filtrValues) {
			if (filtrInfo.info.type === 'checkboxList') {
				if(groupName === 'noGroup') {
					for (var key in filtrValues[groupName]) {
						setEnableDisableFilter(filtrValues[groupName][key],filtrEl);
					}
				}else{
					for (var subGroup in filtrValues[groupName]) {
						for (var filterItem in filtrValues[groupName][subGroup]) {
							setEnableDisableFilter(filtrValues[groupName][subGroup][filterItem],filtrEl);
						}
					}
				}
			}
		}
	}
	function setEnableDisableFilter(filterItem, parent){
		var filterItemValue = filterItem.value,
			isEnable = filterItem.enable;
		if (filterItemValue) {
			var filterLabel = $('.js-filter-label[data-value="' + filterItemValue + '"]', $(parent));

			if (isEnable) {
				$(filterLabel).removeClass('disabled').find('input[type="checkbox"]').removeAttr('disabled');
			} else {
				$(filterLabel).addClass('disabled').find('input[type="checkbox"]').attr('disabled', 'disabled');
			}
		}
	}
};
WishList.prototype.remove = function( productsId, callback ) {
	var sendData = { products_id: productsId };
	if( window.PROSKTR.ajax_csrf_token !== undefined ) {
		sendData.csrf_token = window.PROSKTR.ajax_csrf_token;
	}
	$.ajax({
		url: '/ajax.php?type=wishlist&action=remove',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		context: this,
		success: function(msg) {
			if(!msg.products.length && typeof mindbox === "function"){
				mindbox("async", {
					operation: "Website.ClearWishList",
					data: {}
				});
			}
			this.setTotal( msg.count );
			this.list = msg.list;
			if( this.useLocalStorage ) {
				localStorage.setItem('wishList', JSON.stringify( this.list ));
			}
			if( typeof this.options.afterRemove == 'function' ) {
				return this.options.afterRemove( productsId, msg );
			}
			if( typeof callback == 'function' ) {
				callback();
			}
			if( Array.isArray(productsId) ) {
				for (var i = productsId.length - 1; i >= 0; i--) {
					removeWishlistEvent( productsId[i], msg );
				}
			} else {
				removeWishlistEvent( productsId, msg );
			}
		}
	});
};
WishList.prototype.add = function( productsId, callback ) {
	var sendData = { products_id: productsId };
	if( window.PROSKTR.ajax_csrf_token !== undefined ) {
		sendData.csrf_token = window.PROSKTR.ajax_csrf_token;
	}
	$.ajax({
		url: '/ajax.php?type=wishlist&action=add',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		context: this,
		success: function(msg) {
			this.setTotal( msg.count );
			this.list.push( productsId );
			if( this.useLocalStorage ) {
				localStorage.setItem('wishList', JSON.stringify( this.list ));
			}
			if( typeof callback == 'function' ) {
				callback( msg );
			}
			if( typeof this.options.afterAdd == 'function' ) {
				return this.options.afterAdd( productsId, msg );
			}
			if( this.useLocalStorage ) {
				if( msg.count != this.list.length ) {
					this.loadList();
				}
			}
			var productList = [],
				items = [];

			for (var i = 0;i<msg.addedProducts.length;i++){
				var product = msg.addedProducts[i];

				if(product.warehouse_artikul2)
				{
					items.push({
						item_name: product.products_name,
						item_id: product.products_id,
						price: product.current_price,
						item_brand: product.manufacturers_name,
						item_category: product.polveshi,
						item_category2: product.cattovara_main,
						item_category3: product.cattovara_medium,
						item_category4: product.cattovara,
						item_variant: product.color,
						item_list_name: product.uriName,
						index: 1,
						quantity: 1
					});
					productList.push({
						productGroup: {
							ids: {
								website: product.warehouse_artikul2
							}
						},
						count: 1,
						pricePerItem: product.products_price
					});
				}
			}
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				event: 'add_to_wishlist',
				ecommerce: {
					items: items,
				}
			});
			if(typeof mindbox === "function" && msg.addedProducts[0]) {
				var addedProduct = msg.addedProducts[0];

				mindbox("async", {
					operation: "Website.AddToWishList",
					data: {
						customer: {
							email: ""
						},
						addProductToList: {
							product: {
								ids: {
									website: addedProduct.warehouse_artikul2,
								}
							},
							productGroup: {
								ids: {
									website: addedProduct.products_model
								}
							},
							pricePerItem: addedProduct.current_price
						}
					}
				});
			}
			try {
				ga('send', 'event', 'AB_Wishlist', 'Show_wishlist', 'Show_wishlist_Click', {'nonInteraction': 1});
				window._fbq = window._fbq || [];
				window._fbq.push(['track', 'AddToWishlist', {}]);
			} catch(e) {}
			addWishlistEvent( productsId, msg );
		}
	});
};
